import {Box, Card, Divider, Grid, Typography} from '@mui/material';
import {ImageCarousel} from 'components/common/imageCarousel';
import {ReactComponent as TrendUp} from 'svg-icons/trendUp.svg';
import {ReactComponent as TrendDown} from 'svg-icons/trend-down.svg';
import styles from './styles.module.scss';
import classNames from 'classnames';
import ItemActions from './item-actions';
import PropertyItemFooter from './item-footer';
import React, {useMemo} from 'react';
import {CurrencyCode, PropertyLabelsType, PropertyListItemType, PublicationStatus} from 'types';
import ItemsFeature from './items-feature';
import {currencySymbols} from 'constants/index';
import ItemContacts from './item-contacts';
import PropertyHeader from '../property-header';
import PropertyAddress from '../property-address';
import NoImage from '../no-image';
import {formatNumber} from '../../../helpers';
import {useParams} from 'react-router-dom';
import BasePropertyItemFooter from './base-propperty-footer';
import PropertyHeaderParser from '../property-header-parser';

const PropertyItem: React.FC<{
  item: PropertyListItemType;
  filter: any;
  refetch: any;
  parentList: 'base' | 'parser';
}> = ({
  item: propertyItem,
  parentList,
  item: {
    address,
    features,
    media,
    price: {original, converted},
    sources,
    contacts,
    latest_publication,
    labels,
    publication_status,
    status
  },
  refetch
}) => {
  const {filter: filterName = ''} = useParams();
  const getBaseFilters = () => {
    switch (filterName) {
      case 'active':
        return ['comment', 'archive', 'edit', 'publicationsList', 'repost', 'trash'];
      case 'unpublished':
        if (publication_status === PublicationStatus.UNPUBLISHED) {
          return ['published', 'doNotPublish', 'edit', 'comment', 'archive', 'trash'];
        }
        if (publication_status === PublicationStatus.DO_NOT_PUBLISH) {
          return ['edit', 'comment', 'archive', 'trash'];
        }
        return ['edit', 'doNotPublish', 'comment', 'archive', 'trash'];
      default:
        return ['edit', 'comment', 'revivedFromArchive', 'trash'];
    }
  };

  const getParserFilters = () => {
    switch (filterName) {
      case 'trash':
        return ['putOff', 'merge', 'edit'];
      default:
        const alreadyPostponed = labels.some((item) => item.label.alias === PropertyLabelsType.POSTPONED);

        return ['putOff', 'merge', 'edit', ...(!alreadyPostponed ? ['postponed'] : []), 'trash'];
    }
  };
  const actionsList = parentList === 'base' ? getBaseFilters() : getParserFilters();
  const priceLower = labels?.filter((item) => item.label.alias === PropertyLabelsType.PRICE_LOWER);
  const priceHigher = labels?.filter((item) => item.label.alias === PropertyLabelsType.PRICE_HIGHER);

  const currencyString = {
    [CurrencyCode.UAH]: 'грн',
    [CurrencyCode.USD]: '$',
    [CurrencyCode.EUR]: '€'
  };

  const currentAssignee = useMemo(() => {
    return propertyItem?.assignee || propertyItem?.author;
  }, [propertyItem.assignee, propertyItem.author]);

  return (
    <>
      <Grid item className={styles.itemWrapper}>
        <Card sx={{bgcolor: 'background.secondary', px: '12px'}}>
          <Box className={styles.box}>
            {parentList === 'base' ? (
              <PropertyHeader
                sources={sources}
                title={propertyItem.title}
                type={propertyItem.estate_type}
                propertyId={propertyItem.id}
                contacts={propertyItem.contacts}
              />
            ) : (
              <PropertyHeaderParser
                sources={sources}
                title={propertyItem.title}
                type={propertyItem.estate_type}
                propertyId={propertyItem.id}
                contacts={propertyItem.contacts}
              />
            )}
            <Box className={classNames(styles.box, styles.auto)}>
              <Typography variant="body2" sx={{color: 'text.primary'}}>
                Ціна:
              </Typography>
              {!!priceHigher?.length && <TrendUp />}
              {!!priceLower?.length && <TrendDown />}
              <Typography variant="body2" sx={{color: 'text.primary', fontWeight: 700}}>
                {formatNumber(original.amount)} {currencySymbols[original.currency_code]}
              </Typography>
              <Typography variant="body2">
                {converted.amount ? (
                  <>
                    ({formatNumber(converted.amount)} {currencyString[converted.currency_code]})
                  </>
                ) : (
                  `-`
                )}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{mx: '-12px'}} />
          <Grid container spacing={4} sx={{py: '12px'}}>
            <Grid item className={styles.column}>
              <Box className={styles.relative}>
                {media?.length ? (
                  <ImageCarousel imageList={media.length ? media : []} />
                ) : (
                  <Box className={styles.noImage}>
                    <NoImage />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item className={styles.column}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <ItemsFeature features={features} address={address} />
            </Grid>
            <Grid item className={styles.column}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <Box className={styles.row}>
                <PropertyAddress address={address} />
              </Box>
            </Grid>
            <Grid item className={styles.column}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <ItemContacts contacts={contacts} />
            </Grid>
            <Grid item className={classNames(styles.column, styles.maxW)}>
              <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
              <ItemActions
                assignee={currentAssignee}
                propertyId={propertyItem.id}
                actionList={actionsList}
                refetch={refetch}
                parentList={parentList}
                propertyStatus={status}
              />
            </Grid>
          </Grid>
          <Divider sx={{mx: '-12px'}} />
          {parentList === 'base' ? (
            <BasePropertyItemFooter
              propertyId={propertyItem?.id}
              lastTimeContactedAt={propertyItem?.last_time_contacted_at}
              createdAt={propertyItem?.created_at}
              latestPublicationAt={latest_publication?.posted_at}
              postLink={latest_publication?.post_link}
              contacts={contacts}
              labels={labels}
              offerType={propertyItem.offer_type}
              parsedPropertyId={propertyItem?.parsed_property_id}
            />
          ) : (
            <PropertyItemFooter
              propertyId={propertyItem?.id}
              lastTimeContactedAt={propertyItem?.last_time_contacted_at}
              createdAt={propertyItem?.created_at}
              latestPublicationAt={latest_publication?.posted_at}
              sources={sources}
              contacts={contacts}
              labels={labels}
              offerType={propertyItem.offer_type}
              isBase={false}
            />
          )}
        </Card>
      </Grid>
    </>
  );
};

export default PropertyItem;
