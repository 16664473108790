import styles from './styles.module.scss';
import {Box, Button, CircularProgress, Dialog, IconButton, Paper, Typography} from '@mui/material';
import React, {useState} from 'react';
import {postLabels} from 'api/labels';
import {PropertyLabelsType} from 'types';
import {useToastContext} from 'contexts/ToastContext';
import {deleteProperty} from '../../../../api/base';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {PROPERTY_URL} from '../../../../constants/routes';
import {CloseIcon} from 'yet-another-react-lightbox';

const ChangeTimeConfirm: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  submit: (arg: {forceTimeUpdate: boolean}) => void;
}> = ({setOpen, open, submit}) => {
  const [loading] = useState(false);

  const onSubmit = async () => {
    submit({forceTimeUpdate: true});
    setOpen(false);
  };

  const onAbort = async () => {
    submit({forceTimeUpdate: false});
    setOpen(false);
  };

  return (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      className={styles.dialogWrapper}
    >
      <Paper component={'form'} sx={{p: 2.4}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '16px'
          }}
        >
          <Typography sx={{color: 'text.primary', fontSize: '1.3rem'}} className={styles.dialogTitleText} variant="h5">
            <strong>Були внесені зміни</strong>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={{mb: '24px', color: 'text.customGray', fontSize: '0.9rem'}} className={styles.dialogBodyText}>
          <strong>Змінити дату контакту на сьогоднішню?</strong>
        </Typography>
        <Box className={styles.btns}>
          <Button
            type="button"
            variant="text"
            className={styles.cancelBtn}
            sx={{color: 'text.primary', textTransform: 'none'}}
            onClick={onAbort}
          >
            <strong>Не змінювати</strong>
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={onSubmit}
            sx={{textTransform: 'none'}}
            className={styles.button}
            disabled={loading}
          >
            {loading ? <CircularProgress size={'24px'} /> : <strong>Так</strong>}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default ChangeTimeConfirm;
