import {useNavigate, useParams} from 'react-router-dom';
import {useQuery, useQueryClient} from 'react-query';
import PropertyLayout from './property-layout';
import React, {useEffect, useRef, useState} from 'react';
import {getParsedProperty, putParsedProperties} from 'api/properties';
import Loader from '../loader';
import PropertyForm from './property-form';
import {useFormWatcherContext} from 'contexts/FormWatcherProvider';
import {useToastContext} from 'contexts/ToastContext';
import {getBaseProperty, postProperties, putProperties} from 'api/base';
import {formatDateString} from 'helpers';
import {PROPERTY_URL} from '../../../constants/routes';
import {useUserProvider} from '../../../contexts/UserContextContext';
import {CityCode} from '../../../types';
import dayjs from 'dayjs';

const getRawObjects = (data, stringKey) =>
  Object.entries(data).flatMap(([key, value]) => {
    if (!key.startsWith(stringKey)) {
      return [];
    }
    return [{[key]: value}];
  });

const PropertyPage = ({title, type = 'edit', isParser = true}) => {
  const {id: propertyId} = useParams();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const queryClient = useQueryClient();
  const [renderLabels, setRenderLabels] = useState([]);

  const {userLoading} = useUserProvider();

  const {setPropertyImages, propertyImages} = useFormWatcherContext();
  const {setToastData, setOpenToast} = useToastContext();
  const queryKey = isParser ? `parsed-properties/${propertyId}` : `property/${propertyId}`;

  const {
    data: resultData = [],
    isLoading = true,
    refetch,
    isFetching
  } = useQuery([queryKey], () => (isParser ? getParsedProperty({id: propertyId}) : getBaseProperty({id: propertyId})), {
    enabled: type === 'edit',
    refetchOnMount: true,
    retry: false,
    keepPreviousData: false,
    cacheTime: 0
  });

  const ignoredKeys = [
    'price',
    'address.city_code',
    'address.district',
    'address.residential_complex',
    'address.adornment_street_name',
    'address.building_type',
    'address.metro_stations',
    'address.riverbank',
    'address.street_type',
    'address.street_name',
    'address.newly_built',
    'address.building',
    'address.section',
    'address.entrance',
    'address.apartment',
    'address.geo_link',
    'address.is_center',
    'assignee',
    'adornment_price',
    'last_time_contacted_at',
    'adornment_address.street_name',
    'adornment_address',
    'callback_at',
    'contacts'
  ];

  const ignoredErrorKeys = ['address', 'contacts', 'price', 'features'];

  const handleFormSubmit = async (data, ...rest) => {
    const needForceContactTimeUpdate = rest[0].forceTimeUpdate;
    const formData = new FormData();
    Object.entries(data).map(([key, value]) => {
      formData[key] = value;
    });

    const rawSources = getRawObjects(data, 'source_');

    const transformedSources = Object.values(
      rawSources.reduce((acc, item) => {
        const [key, value] = Object.entries(item)[0];
        const match = key.match(/(.+?)_(\d+)$/);
        if (match) {
          const [, field, id] = match;
          if (!acc[id]) {
            acc[id] = {id: parseInt(id), type: '', source_id: '', link: '', primary: false};
          }
          if (field === 'source_type') acc[id].type = value;
          if (field === 'source_id') acc[id].source_id = value;
          if (field === 'source_link') acc[id].link = value;
        }
        return acc;
      }, {})
    );

    const transformedContacts = data.contacts.map((contact) => {
      if (contact.extraField) {
        contact.phone_numbers.push({
          id: null,
          phone_number: contact.extraField,
          linked: false
        });
        delete contact.extraField;
      }
      contact.id = contact.initialId;
      delete contact.initialId;
      contact.phone_numbers = contact.phone_numbers
        .filter((number) => Boolean(number.phone_number))
        .map((contactPhoneNumber) => ({
          id: contactPhoneNumber.id || null,
          linked: contactPhoneNumber.linked || false,
          phone_number: contactPhoneNumber.phone_number.replace(/\D/g, '')
        }));
      return contact;
    });

    const normalizedData = Object.keys(formData).reduce(
      (acc, key) => {
        if (
          acc[key] ||
          ignoredKeys.includes(key) ||
          !Boolean(key) ||
          key.startsWith('source_') ||
          key.startsWith('contact_')
        ) {
          return acc;
        }

        acc[key] = data[key];
        return acc;
      },
      {
        assignee: {
          from_id: resultData?.assignee?.id,
          to_id: data.assignee?.value
        },
        price: {amount: data.price, currency_code: data.adornment_price},
        sources: transformedSources.map((source) => {
          return {
            ...source,
            primary: +source.id === +data.source_primary
          };
        }),
        address: {
          ...(typeof data.address?.city_code === 'string' && {
            city_code: data.address?.city_code
          }),
          ...(data.address?.city_code?.value && {city_code: data.address.city_code.value}),
          ...(data?.address?.district?.value && {district_id: data?.address?.district.value}),
          ...(data?.address?.district?.label &&
            !data?.address?.district?.value && {
              district_name: data?.address?.district?.label
            }),
          ...(typeof data?.address?.district === 'string' && {
            district_name: data?.address?.district
          }),
          ...(data.address?.residential_complex?.value &&
            +data.address?.newly_built === 1 && {
              residential_complex_id: data.address?.residential_complex.value
            }),
          ...((data.address?.residential_complex?.label && +data.address?.newly_built === 1) ||
            (typeof data.address?.residential_complex === 'string' &&
              +data.address?.newly_built === 1 && {
                residential_complex_name:
                  typeof data.address?.residential_complex === 'string'
                    ? data.address?.residential_complex
                    : data.address?.residential_complex?.label
              })),
          ...(data?.adornment_address?.street_name && {
            street_type: data.adornment_address.street_name
          }),
          ...(data.address?.street_name && {
            street_name: data.address?.street_name
          }),
          ...(data.address?.building_type?.value &&
            +data.address?.newly_built === 0 && {
              building_type_id: data.address?.building_type.value
            }),
          ...(typeof data?.address?.building_type === 'string' &&
            +data.address?.newly_built === 0 && {building_type_name: data?.address?.building_type}),
          newly_built: +data.address?.newly_built === 1,
          building: data.address?.building ? data.address?.building : '',
          section: data.address?.section ? data.address?.section : '',
          entrance: data.address?.entrance ? data.address?.entrance : '',
          apartment: data.address?.apartment ? data.address?.apartment : '',
          geo_link: data.address?.geo_link ? data.address?.geo_link : '',
          is_center: data.address?.is_center,
          ...(data.address?.city_code === CityCode.KYIV && data.address?.riverbank
            ? {
                riverbank: data.address?.riverbank
              }
            : {}),
          ...(data.address?.city_code === CityCode.KYIV && data.address?.metro_stations?.length
            ? {
                metro_stations: data.address?.metro_stations.filter((item) => Boolean(item))
              }
            : {}),
          ...(data.address?.city_code === CityCode.KYIV &&
            data?.address?.subdistrict && {subdistrict_id: data?.address?.subdistrict})
        },
        contacts: transformedContacts.filter((item) => Boolean(item?.name)),
        labels: renderLabels?.map((item) => ({
          alias: item.label.alias,
          comment: item.comment
        })),
        media: propertyImages?.length ? propertyImages.map((image) => ({url: image.url, uuid: image.uuid})) : [],
        ...(needForceContactTimeUpdate
          ? {last_time_contacted_at: dayjs().format('YYYY-MM-DD')}
          : {
              ...(data?.last_time_contacted_at && {
                last_time_contacted_at: formatDateString(data.last_time_contacted_at)
              })
            }),

        ...(data?.callback_at &&
          !isNaN(new Date(data.callback_at).getTime()) && {
            callback_at: formatDateString(data.callback_at)
          })
      }
    );

    try {
      const response = isParser
        ? await putParsedProperties(propertyId, normalizedData)
        : type === 'edit'
        ? await putProperties(propertyId, normalizedData)
        : await postProperties(normalizedData);
      setToastData({
        toastBody: response.message,
        toastTitle: 'Успіх',
        toastType: 'success'
      });
      setOpenToast(true);
      if (response.success && type === 'create') {
        navigate(`${PROPERTY_URL}/unpublished`);
        return;
      }

      if (response.success) {
        isParser
          ? await queryClient.invalidateQueries([`parsed-properties/${propertyId}`])
          : await queryClient.invalidateQueries([`property/${propertyId}`]);
      }
    } catch (error) {
      if (error?.errors?.message) {
        setToastData({
          toastBody: error.errors.message,
          toastTitle: 'Помилка',
          toastType: 'error'
        });
        setOpenToast(true);
      }
      if (error?.errors?.errors) {
        const normalizedErrors = Object.keys(error.errors.errors).reduce(
          (acc, key) => {
            if (acc[key] || ignoredErrorKeys.includes(key)) {
              return acc;
            }

            acc[key] = error.errors.errors[key];
            return acc;
          },
          {
            districts_autocomplete: error.errors.errors.address
          }
        );
        Object.entries(normalizedErrors).forEach(([key, value]) => {
          if (key.startsWith('address.')) {
            if (key.endsWith('street_name') || key.endsWith('street_type')) {
              formRef.current.setFormErrors(key.toString(), value);
              return;
            }
            if (key.endsWith('_name') || key.endsWith('_id')) {
              const trimmedKey = key.replace(/(_name|_id)$/, '');
              formRef.current.setFormErrors(trimmedKey, value);
              return;
            }
            if (key.endsWith('_name') || key.endsWith('_id')) {
              const trimmedKey = key.replace(/(_name|_id)$/, '');
              formRef.current.setFormErrors(trimmedKey, value);
              return;
            }
            formRef.current.setFormErrors(key.toString(), value);
          }
          if (key.startsWith('price.')) {
            formRef.current.setFormErrors(`price`, value);
          }
          if (key.startsWith('features.')) {
            formRef.current.setFormErrors(key, value);
          }
          const existFieldKey = Object.entries(formRef.current.defaultValues).find(
            ([defaultKey, defaultValue]) => defaultKey === key
          );
          if (!existFieldKey?.length) {
            console.log('error', key, value);
            return;
          }
          formRef.current.setFormErrors(key, value);
        });
      }
    }
  };

  useEffect(() => {
    setRenderLabels(resultData.labels);
  }, [resultData.labels]);

  useEffect(() => {
    setPropertyImages(resultData.media);
  }, [resultData.media, setPropertyImages]);

  return (
    <PropertyLayout
      title={title}
      labels={renderLabels}
      setRenderLabels={setRenderLabels}
      loading={isLoading}
      formRef={formRef}
      type={type}
      refetch={refetch}
      isFetching={isFetching}
      publicationStatus={resultData?.publication_status}
    >
      {isFetching || userLoading ? (
        <Loader />
      ) : (
        <PropertyForm
          data={type === 'edit' ? resultData : null}
          isParser={isParser}
          ref={formRef}
          onSubmit={handleFormSubmit}
          type={type}
        />
      )}
    </PropertyLayout>
  );
};

export default PropertyPage;
