import {
  Children,
  CityCode,
  ContactRole,
  CurrencyCode,
  EastType,
  HeatingStatus,
  ISelectItem,
  OfferType,
  Pets,
  PropertyLabelsType,
  PropertyRiverbank,
  PropertySourceType,
  PropertyStatus,
  PublicationState,
  PublicationStatus,
  StreetType
} from '../types';

export const childrenAllowedStatus: Record<Children, any> = {
  [Children.UNKNOWN]: 'Невідомо',
  [Children.ALLOWED]: 'Можна',
  [Children.FROM_6_YEARS]: 'Діти від 6 років',
  [Children.NOT_ALLOWED]: 'Без дітей'
};

export const petsAllowedStatus: Record<Pets, any> = {
  [Pets.UNKNOWN]: 'Невідомо',
  [Pets.ALLOWED]: 'Всі тварини',
  [Pets.NOT_ALLOWED]: 'Без тварин',
  [Pets.ONLY_CATS]: 'Коти',
  [Pets.ONLY_DOGS]: 'Собаки'
};

export const publicationStatusNames: Record<PublicationStatus, any> = {
  [PublicationStatus.UNPUBLISHED]: 'Не опублікована',
  [PublicationStatus.DO_NOT_PUBLISH]: 'Не публікувати',
  [PublicationStatus.PUBLISHED]: 'Опублікована',
  [PublicationStatus.POSTPONED]: 'Відкладена',
  [PublicationStatus.PUBLISHING]: 'Публікується'
};

export const publicationStateNames: Record<PublicationState, any> = {
  [PublicationState.PENDING]: 'Публікується',
  [PublicationState.DONE]: 'Опубліковано',
  [PublicationState.FAILED]: 'Помилка',
  [PublicationState.ARCHIVED]: 'Архівовано',
  [PublicationState.POSTPONED]: 'Відкладено'
};

export const labelNames: Record<PropertyLabelsType, any> = {
  [PropertyLabelsType.DUPLICATE]: 'Дублікат',
  [PropertyLabelsType.PRICE_LOWER]: 'Ціна нижче',
  [PropertyLabelsType.PRICE_HIGHER]: 'Ціна вище',
  [PropertyLabelsType.REVIVED_FROM_ARCHIVE]: 'З архіву',
  [PropertyLabelsType.POSTPONED]: 'Відкладено',
  [PropertyLabelsType.AGENT]: 'Агент',
  [PropertyLabelsType.OWNER]: 'Власник',
  [PropertyLabelsType.MAYBE_OWNER]: 'Можливо власник',
  [PropertyLabelsType.TRASH]: 'Смітник'
};

export const currencySymbols: Record<CurrencyCode, string> = {
  UAH: 'грн',
  USD: '$',
  EUR: '€'
};

export const propertyContactRole: Record<ContactRole, any> = {
  [ContactRole.UNKNOWN]: 'Невідомо',
  [ContactRole.AGENT]: 'Агент',
  [ContactRole.NO_FEE_AGENT]: 'Агент без комісії',
  [ContactRole.OWNER]: 'Власник',
  [ContactRole.OUR_EXCLUSIVE]: 'Наш Ексклюзив'
};

export const heatingStatusNames: Record<HeatingStatus, any> = {
  [HeatingStatus.AUTONOMOUS]: 'Автономне'
};

export const propertySourceNames: Record<PropertySourceType, string> = {
  [PropertySourceType.OLX]: 'OLX',
  [PropertySourceType.DOM_RIA]: 'Dom Ria',
  [PropertySourceType.REAL_ESTATE_LVIV]: 'Real-estate.lviv',
  [PropertySourceType.RIELTOR_UA]: 'Rieltor.ua',
  [PropertySourceType.INSTAGRAM]: 'Instagram',
  [PropertySourceType.TELEGRAM]: 'Telegram',
  [PropertySourceType.OTHER]: 'Інший'
};

export const eastTypeNames: Record<EastType, string> = {
  [EastType.HOUSE]: 'Будинок',
  [EastType.APARTMENT]: 'Квартира'
};

export const sourceTypeNames: Record<PropertySourceType, string> = {
  [PropertySourceType.OLX]: 'ОЛХ',
  [PropertySourceType.DOM_RIA]: 'DIM RIA',
  [PropertySourceType.REAL_ESTATE_LVIV]: 'Real-estate.lviv',
  [PropertySourceType.RIELTOR_UA]: 'Rieltor Ua',
  [PropertySourceType.INSTAGRAM]: 'Instagram',
  [PropertySourceType.TELEGRAM]: 'Telegram',
  [PropertySourceType.OTHER]: 'Інший'
};

export const propertyStatusNames: Record<PropertyStatus, string> = {
  [PropertyStatus.ACTIVE]: 'Активна',
  [PropertyStatus.RENTED_BY_US]: 'Закрито нами',
  [PropertyStatus.RENTED_BY_OTHERS]: 'Закрито не нами'
};

export const offerTypeNames: Record<OfferType, string> = {
  [OfferType.RENT]: 'Оренда',
  [OfferType.SALE]: 'Продаж'
};

export const currencyCodeNames: Record<CurrencyCode, string> = {
  [CurrencyCode.UAH]: 'UAH',
  [CurrencyCode.USD]: 'USD',
  [CurrencyCode.EUR]: 'EUR'
};

export const propertyRooms: ISelectItem[] = Array.from({length: 10}, (_, i) => ({
  label: (i + 1).toString(),
  value: (i + 1).toString()
}));

export const propertyFiltersRoom: ISelectItem[] = [
  {label: '1', value: '1'},
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4 плюс', value: '4-plus'}
];

export const API_ROUTES = {
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  ADMINS_ME: '/admins/me',
  ADMINS: '/admins',
  ADMINS_SEARCH: '/admins/search',
  ADMINS_ALLOWED_ROLES: '/admins/me/allowed-roles',
  PROPERTIES: '/properties',
  PROPERTIES_ARCHIVED: '/properties/archived',
  PROPERTIES_FROM_PARSED: '/properties/create-from-parsed',
  PARSED_PROPERTIES: '/parsed-properties',
  CITIES: '/locations/cities',
  CURRENCY: 'currencies/convert',
  STREETS: '/locations/streets',
  DISTRICTS: '/locations/districts',
  BUILDING_TYPES: '/locations/building-types',
  RESIDENTIAL_COMPLEXES: '/locations/residential-complexes',
  LABELS: '/labels',
  TEMPORARY_MEDIA: '/media/temporary',
  METRO_STATIONS: '/locations/metro-stations',
  SUBDISTRICTS: '/locations/subdistricts'
};

export const streetTypeNames: Record<StreetType, string> = {
  [StreetType.STREET]: 'Вулиця',
  [StreetType.AVENUE]: 'Проспект',
  [StreetType.SQUARE]: 'Площа',
  [StreetType.BOULEVARD]: 'Бульвар',
  [StreetType.LANE]: 'Провулок'
};

export const riverBankTypeNames: Record<PropertyRiverbank, string> = {
  [PropertyRiverbank.LEFT]: 'Лівий берег',
  [PropertyRiverbank.RIGHT]: 'Правий берег',
  [PropertyRiverbank.NONE]: 'Неважливо'
};

export const floorList: ISelectItem[] = Array.from({length: 100}, (_, i) => ({
  label: (i + 1).toString(),
  value: (i + 1).toString()
}));

export const urlPattern = /^(https?:\/\/)?((localhost|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))(:\d+)?(\/\S*)?$/;

export const defaultPropertiesFilterValues: Record<string, any> = {
  offer_type: 'rent',
  estate_type: 'apartment'
};

export const PREFERRED_CITY_KEY = 'preferred_city';
