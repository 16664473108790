import {useCallback, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {SIGN_IN_BASE_URL} from '../constants/routes';
import {useLocalStorage} from './useLocalStorage';
import {PREFERRED_CITY_KEY} from '../constants';

export const useAuth = () => {
  const navigate = useNavigate();

  const {value: token, setItem: setToken, removeItem: removeToken} = useLocalStorage('token');

  const handleLogin = useCallback(
    (value) => {
      setToken(value);
      navigate('/');
    },
    [navigate, setToken]
  );

  const handleLogout = useCallback(() => {
    removeToken();
    localStorage.removeItem(PREFERRED_CITY_KEY);
    navigate(SIGN_IN_BASE_URL);
  }, [navigate, setToken]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    if (accessToken) {
      handleLogin(accessToken);
    }
  }, [handleLogin]);

  return useMemo(
    () => ({
      token,
      login: handleLogin,
      logout: handleLogout
    }),
    [token, handleLogin, handleLogout]
  );
};
