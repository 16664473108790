import {useEffect} from 'react';

import {useAuth} from '../../../hooks/useAuth';
import {useUserProvider} from '../../../contexts/UserContextContext';

const SignOutPage = () => {
  const {logout} = useAuth();
  const {setUser} = useUserProvider();

  useEffect(() => {
    setUser(null);
    logout();
  }, [logout]);

  return (
    <div>
      <h2>Ви вийшли з облікового запису</h2>
    </div>
  );
};

export default SignOutPage;
