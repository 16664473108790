import {CityCode} from '../../../../types';
import FieldWidget from '../index';
import React from 'react';
import {useQuery} from 'react-query';
import {getSubdistricts} from '../../../../api/filters';

const Subdistricts: React.FC<{
  control: any;
  register: any;
  gridSize?: number;
  multiple: boolean;
  citySelectValue: CityCode;
  filedName: any;
}> = ({control, register, gridSize = 6, multiple = false, citySelectValue, filedName}) => {
  const {data: subdistricts = [], isLoading: isSubdistrictsLoading} = useQuery(
    [],
    async () => await getSubdistricts({city_code: citySelectValue}),
    {
      enabled: citySelectValue === CityCode.KYIV
    }
  );
  return (
    <FieldWidget
      control={control}
      register={register}
      items={subdistricts.map((item) => ({
        label: item.name,
        value: item.id
      }))}
      filedName={filedName}
      gridSize={gridSize}
      multiple={multiple}
      disabled={citySelectValue !== CityCode.KYIV}
    />
  );
};

export default Subdistricts;
