import React, {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import {IAdmin} from '../types';

interface FilterContextType {
  user: IAdmin | null;
  userLoading: boolean;
  setUser: Dispatch<SetStateAction<IAdmin | null>>;
  setUserLoading: Dispatch<SetStateAction<boolean>>;
}

export const UserContextContext = createContext<FilterContextType>({
  user: null,
  userLoading: false,
  setUser: () => {},
  setUserLoading: () => {}
});

export const UserProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [user, setUser] = useState<IAdmin | null>(null);
  const [userLoading, setUserLoading] = useState<boolean>(false);
  return (
    <UserContextContext.Provider value={{user, userLoading, setUser, setUserLoading}}>
      {children}
    </UserContextContext.Provider>
  );
};

export const useUserProvider = () => {
  const context = useContext(UserContextContext);
  if (!context) {
    throw new Error('UserProvider must be used within a LoadingProvider');
  }
  return context;
};
