import React, {useMemo} from 'react';
import FieldWidget from '../index';
import {FormFields} from 'types';
import {ISelectItem} from '../../table/types';
import {getDistrict} from '../../../../api/filters';
import {useQuery} from 'react-query';
import {debounce} from '../../../../helpers';

const District: React.FC<{
  control: any;
  register: any;
  defaultItems: ISelectItem[];
  gridSize?: number;
  multiple?: boolean;
  required?: boolean;
  label?: string | null;
  enableTrigger: string;
}> = ({
  control,
  register,
  defaultItems,
  gridSize = 6,
  multiple = false,
  required = true,
  label = null,
  enableTrigger
}) => {
  const [name, setName] = React.useState('');

  const debouncedGetDistrict = useMemo(() => {
    return debounce((name: string, cityCode: string, callback: (result: any) => void) => {
      return callback(getDistrict({name, city_code: cityCode}));
    }, 300);
  }, []);

  const {data: districts = [], isLoading: isDistrictsLoading} = useQuery(
    ['locations/districts', name, enableTrigger],
    () =>
      new Promise((resolve) => {
        debouncedGetDistrict(name, enableTrigger, resolve);
      }),
    {
      retry: false,
      enabled: !!enableTrigger
    }
  );

  const onChange = debounce((name: string, value: any) => {
    if (typeof value !== 'string') {
      return;
    }
    setName(value);
  }, 500);

  return (
    <FieldWidget
      disabled={!enableTrigger}
      control={control}
      register={register}
      filedName={FormFields['address.district']}
      gridSize={gridSize}
      items={
        Array.isArray(districts) && districts.length
          ? districts.map((district) => ({
              label: district.name,
              value: district.id
            }))
          : defaultItems
      }
      rules={required ? {required: "Поле обов'язкове."} : {}}
      multiple={multiple}
      onChange={onChange}
      label={label}
    />
  );
};

export default District;
