import styles from './styles.module.scss';
import {Box, Button, Dialog, IconButton, Paper, Skeleton, Typography} from '@mui/material';
import InputField from '../../inputField';
import React, {Dispatch, SetStateAction, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import useLabels from 'hooks/useLabels';
import SelectDropdown from '../../selectDropdown';
import {useParams} from 'react-router-dom';
import Toast from '../../toast';
import {ILabelListItem, PropertyLabels} from '../../../../types';
import {useFormWatcherContext} from '../../../../contexts/FormWatcherProvider';
import {CloseIcon} from 'yet-another-react-lightbox';

const AddLabelDialog: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  onAddLabel: Dispatch<SetStateAction<PropertyLabels[]>>;
  propertyLabels: PropertyLabels[];
}> = ({setOpen, open, onAddLabel, propertyLabels}) => {
  const {id: propertyId} = useParams();
  const {labelsList, isLabelLoading} = useLabels();
  const {control, handleSubmit, watch, register, reset} = useForm({
    mode: 'onSubmit'
  });
  const {setFormContextChanged} = useFormWatcherContext();

  const [openToast, setOpenToast] = useState(false);
  const [{toastType, toastTitle, toastBody}, setToastData] = useState<{
    toastType: 'success' | 'error' | 'warning';
    toastTitle: string;
    toastBody: string;
  }>({
    toastType: 'success',
    toastBody: '',
    toastTitle: ''
  });

  const newLabelValue: string = watch('label', '');

  const onSubmit = (data: Record<string, string>) => {
    if (!propertyId) {
      return;
    }

    const isLabelAlreadyExist = propertyLabels.find((label) => label.label.alias === data.label);

    if (isLabelAlreadyExist) {
      setToastData({
        toastBody: 'Лейба вже існує',
        toastTitle: 'Помилка',
        toastType: 'error'
      });
      setOpenToast(true);
      return;
    }

    const selectedLabel = labelsList.find((label) => label.alias === newLabelValue);
    if (!selectedLabel) {
      setOpen(false);
      return;
    }
    onAddLabel((state) => [
      ...state,
      {
        id: selectedLabel.id,
        label: {
          alias: selectedLabel.alias,
          name: selectedLabel.name
        },
        details: {
          duplicate_id: null,
          comment: data.comment || ''
        }
      }
    ]);
    setFormContextChanged(true);
    setOpen(false);
    reset();
  };

  const labelCommentRequired = useMemo(() => {
    const filteredLabel = labelsList.find((label) => label.alias === newLabelValue);
    return !!filteredLabel?.requires_comment;
  }, [labelsList, newLabelValue]);

  const items = useMemo(() => {
    return labelsList.map((label) => ({
      label: label.name,
      value: label.alias
    }));
  }, [labelsList]);

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '24px'
            }}
          >
            <Typography variant={'h5'}>
              <strong>Додавання лейби</strong>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {isLabelLoading ? (
            <Skeleton sx={{width: 1, height: '40px!important', transform: 'none'}} animation={'wave'} />
          ) : (
            <SelectDropdown
              control={control}
              register={register}
              items={items}
              label={''}
              name={'label'}
              size={'small'}
              placeholder={'Виберіть лейбу'}
            />
          )}
          {labelCommentRequired && (
            <Box className={styles.comment}>
              <InputField
                control={control}
                register={register}
                label={'Додайте коментар'}
                onChange={() => {}}
                name={'comment'}
                size={'medium'}
                rules={{
                  required: {value: true, message: "Поле обов'язкове"},
                  validate: (value: string) => {
                    if (value.trim().length < 3) {
                      return 'Поле повинно містити мінімум 3 символи, невраховуючи пробіли';
                    }
                    return true;
                  }
                }}
              />
            </Box>
          )}
          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setOpen(false);
                reset();
              }}
            >
              <strong>Скинути</strong>
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              type={'button'}
              onClick={handleSubmit(onSubmit)}
              disabled={!Boolean(newLabelValue)}
            >
              <strong>Додати</strong>
            </Button>
          </Box>
        </Paper>
      </Dialog>
      <Toast
        open={openToast}
        setOpen={setOpenToast}
        type={toastType}
        message={{
          title: toastTitle,
          body: toastBody
        }}
      />
    </>
  );
};

export default AddLabelDialog;
