import {Button, Card, CardMedia} from '@mui/material';
import styles from '../styles.module.scss';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import {IPropertyMedia} from '../../../../types';

const ListCard: React.FC<{
  onCrossClick: (arg: string) => void;
  item: IPropertyMedia & {index: number};
  setOpen: any;
}> = ({onCrossClick, item, setOpen}) => {
  return (
    <Card sx={{position: 'relative'}} className={styles.card}>
      <Button
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          minWidth: 0,
          minHeight: 0,
          padding: 0,
          zIndex: 2
        }}
        onClick={() => onCrossClick(item.uuid)}
      >
        <CancelIcon
          sx={{
            borderRadius: '50%',
            width: 15,
            height: 15,
            backgroundColor: 'red',
            color: '#fff'
          }}
        />
      </Button>
      <CardMedia
        onClick={() => {
          setOpen((state: any) => ({
            open: true,
            slideIndex: item.index
          }));
        }}
        component="img"
        image={item.url}
        alt={`Image ${item.index}`}
      />
    </Card>
  );
};

export default ListCard;
