import {API_ROUTES} from '../../../../constants';
import {CityCode} from '../../../../types';
import FieldWidget from '../index';
import React from 'react';
import {useQuery} from 'react-query';
import {getMetroStations} from '../../../../api/filters';

const MetroStations: React.FC<{
  control: any;
  register: any;
  gridSize?: number;
  multiple: boolean;
  citySelectValue: CityCode;
  filedName: any;
}> = ({control, register, gridSize = 6, multiple = false, citySelectValue, filedName}) => {
  const {data: metroStations = [], isLoading: isMetroStatiosLoading} = useQuery(
    [API_ROUTES.SUBDISTRICTS, citySelectValue],
    async () => await getMetroStations({city_code: citySelectValue}),
    {
      enabled: citySelectValue === CityCode.KYIV
    }
  );
  return (
    <FieldWidget
      control={control}
      register={register}
      items={metroStations.map((item) => ({
        label: item.name,
        value: item.id
      }))}
      filedName={filedName}
      gridSize={gridSize}
      multiple={multiple}
      disabled={citySelectValue !== CityCode.KYIV}
    />
  );
};

export default MetroStations;
