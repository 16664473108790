//@ts-nocheck
import dayjs from 'dayjs';
import adFields from './constants/adFields';
import {isArray, isNil} from 'lodash';
import {ContactRolePriority, IPropertyMedia, RawEstateContacts} from './types';

export const checkAndFormatEmptyText = (value) =>
  (typeof value === 'string' || typeof value === 'number') && !!value ? String(value) : '-';

export const dateFormatter = (value) => (value ? dayjs(value).fromNow() : '-');

export const priceFormatter = (price) => (price ? new Intl.NumberFormat().format(Number(price)) : '-');

export const floorFormatter = (floor, amount_of_floors) =>
  `${floor ? floor : '-'}/${amount_of_floors ? amount_of_floors : '-'}`;

export const getRawAdData = (parsedAd) => {
  if (!parsedAd.ad) parsedAd.ad = {};
  const {ad, ...parsed} = parsedAd;

  const result = {...parsed};

  adFields.forEach((key) => {
    result[key] = !isNil(ad[key]) ? ad[key] : parsed[key];
  });

  return result;
};

export const isAdReadyToPublish = (pAd) => {
  const {ad} = pAd;
  return (
    ad.district_id &&
    ad.residential_complex_id &&
    ad.street &&
    ad.building_number &&
    ad.rooms &&
    ad.living_area &&
    ad.floor &&
    ad.amount_of_floors &&
    ad.rent_price &&
    ad.currency &&
    ad.pets &&
    ad.children &&
    pAd.contact &&
    pAd.contact.full_name &&
    pAd.contact.phone_number
  );
};

export const prepareQuery = (params) => {
  let result = '?';
  for (let key in params) {
    if (isArray(params[key])) {
      params[key].forEach((value) => {
        result += `${key}=${value}&`;
      });
    } else if (params[key]) {
      result += `${key}=${params[key]}&`;
    }
  }
  return result.substring(0, result.length - 1);
};

export const debounce = (func, wait, immediate = false) => {
  let timeout;

  return function executedFunction(...args) {
    const later = function () {
      timeout = null;
      if (!immediate) {
        func(...args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func(args);
    }
  };
};

export const getFieldsForRender = (fieldsListArray, fieldsConfigArray) => {
  const resultArray = [];
  if (!fieldsListArray.length) {
    return [];
  }
  fieldsListArray.map((item) => {
    if (!item) {
      return;
    }
    const {name, options, disabled = false} = item;
    fieldsConfigArray.forEach((config) => {
      const object = {};

      if (config.name.toString() === name.toString()) {
        if (options?.length) {
          object.widgetOptions = options;
        }
        if (disabled) {
          object.disabled = disabled;
        }
        resultArray.push({...config, ...object});
      }
    });
  });
  return resultArray;
};

export const getNormalisedOptions = (object): {label: string; value: string}[] => {
  return Object.entries(object).map(([key, value]) => ({
    label: value,
    value: key
  }));
};

function formatRelativeTime(value, unit) {
  const rtf = new Intl.RelativeTimeFormat('uk', {numeric: 'always', style: 'long'});

  return rtf
    .format(value, unit)
    .replace(/тому|через/, '')
    .trim();
}

export const convertTimeAgo = (dateString) => {
  const now = new Date();
  const inputDate = new Date(dateString);

  const diffInSeconds = Math.floor((now - inputDate) / 1000);
  const diffInHours = Math.floor(diffInSeconds / 3600);
  const diffInDays = Math.floor(diffInSeconds / 86400);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);

  if (diffInYears > 0) {
    return formatRelativeTime(diffInYears, 'year');
  } else if (diffInMonths > 0) {
    return formatRelativeTime(diffInMonths, 'month');
  } else if (diffInDays > 0) {
    return formatRelativeTime(diffInDays, 'day');
  } else if (diffInHours > 0) {
    return formatRelativeTime(diffInHours, 'hour');
  } else {
    return 'менше години';
  }
};

export const formatToLocalTime = (isoString: string) => {
  const date = new Date(isoString);

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  });

  return formatter.format(date);
};

export const getSearchParams = (normalizedData: Record<string, any>) => {
  const params = new URLSearchParams();

  Object.entries(normalizedData).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        if (nestedValue !== undefined && nestedValue !== null) {
          params.append(`${key}[${nestedKey}]`, nestedValue.toString());
        }
      });
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        if (item !== undefined && item !== null) {
          params.append(`${key}[]`, item.toString());
        }
      });
    } else if (value) {
      params.append(key, value.toString());
    }
  });

  return decodeURIComponent(params.toString());
};

export const formatCustomTime = (dateString: string, format: string = 'DD.MM.YYYY HH:mm:ss'): string => {
  if (!dateString) {
    return '-';
  }
  try {
    return dayjs(dateString).format(format);
  } catch (error) {
    return '-';
  }
};

export const formatDateString = (dateString: string, dateFormat: string = 'YYYY-MM-DD'): string => {
  const parsedDate = dayjs(new Date(dateString));
  return parsedDate.format(dateFormat);
};

export const mergeArrays = (array1: IPropertyMedia[], array2: IPropertyMedia[]) => {
  const uniqueImages = new Map<string, IPropertyMedia>();

  const addToMap = (array: IPropertyMedia[] = []) => {
    array.forEach((item) => {
      if (!uniqueImages.has(item.uuid)) {
        uniqueImages.set(item.uuid, item);
      }
    });
  };
  addToMap(array1);
  addToMap(array2);
  return Array.from(uniqueImages.values());
};

export const formatNumber = (string: string) => {
  let number = parseFloat(string);

  if (isNaN(number)) {
    throw new Error('Invalid number input');
  }

  let [integerPart, decimalPart] = number.toFixed(2).split('.');

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return `${integerPart}`;
};

export const getErrorMessage = (fieldName: any, errors: any) => {
  return fieldName.split('.').reduce(
    (acc: string, key: string) =>
      //@ts-ignore
      acc?.[key] || null,
    errors
  );
};

export const checkDateStatus = (dateString: string | null) => {
  if (!dateString) {
    return 'default';
  }

  const inputDate = new Date(dateString);
  const currentDate = new Date();

  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const timeDiff = inputDate - currentDate;
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  const expiredDays = Math.abs(daysDiff);

  if (expiredDays >= 0 && expiredDays <= 10) {
    return 'success';
  } else if (expiredDays >= 11 && expiredDays <= 14) {
    return 'warning';
  } else if (expiredDays >= 15) {
    return 'error';
  }

  return 'default';
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const getHighestRolePriority = (contacts: RawEstateContacts[]) => {
  let lowestRole = 'UNKNOWN';
  let lowestPriority = ContactRolePriority.UNKNOWN;

  contacts.forEach((contact) => {
    const rolePriority = ContactRolePriority[contact.role.toUpperCase()] ?? ContactRolePriority.UNKNOWN;

    if (+rolePriority < +lowestPriority) {
      lowestPriority = rolePriority;
      lowestRole = contact.role.toUpperCase();
    }
  });

  return {role: lowestRole.toLowerCase(), priority: lowestPriority};
};

export const formatTimestamp = (isoString: string) => {
  const date = new Date(isoString);

  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `(${day}.${month} ${hours}:${minutes})`;
};
