import styles from './styles.module.scss';
import {Box, Button, CircularProgress, Dialog, IconButton, Paper, Tooltip, Typography} from '@mui/material';
import InputField from '../../inputField';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {postLabels} from 'api/labels';
import {PropertyLabelsType} from '../../../../types';
import {useToastContext} from '../../../../contexts/ToastContext';
import {CloseIcon} from 'yet-another-react-lightbox';

const Postponed: React.FC<{
  setOpen: (value: boolean) => void;
  open: boolean;
  propertyId: string;
  refetch: any;
}> = ({setOpen, open, propertyId, refetch}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const [loading, setLoading] = useState(false);
  const {control, handleSubmit, watch, register, setValue} = useForm({
    mode: 'onSubmit'
  });

  const commentValue: string = watch('comment', '');

  const onSubmit = async (data: Record<string, string>) => {
    if (!propertyId) {
      return;
    }
    setLoading(true);
    const response = await postLabels(propertyId, {
      alias: PropertyLabelsType.POSTPONED,
      details: {comment: data.comment}
    });
    setToastData({
      toastBody: response.message,
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setValue('comment', '');
    setOpenToast(true);
    setLoading(false);
    if (!response.success) {
      return;
    }
    setOpen(false);
    refetch();
  };

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className={styles.dialogWrapper}
      >
        <Paper component={'form'}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '24px'
            }}
          >
            <Typography variant={'h5'}>
              <strong>Причина відкладення</strong>
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={styles.comment}>
            <InputField
              disabled={loading}
              control={control}
              register={register}
              label={'Додайте коментар'}
              onChange={() => {}}
              name={'comment'}
              size={'medium'}
              rules={{
                required: {value: true, message: "Поле обов'язкове"}
              }}
            />
          </Box>
          <Box className={styles.btns}>
            <Button
              type={'button'}
              variant="text"
              onClick={() => {
                setOpen(false);
                setValue('comment', '');
              }}
            >
              <strong>Скинути</strong>
            </Button>
            <Tooltip disableInteractive title={!Boolean(commentValue) && !loading && 'Заповніть поле'} arrow>
              <span>
                <Button
                  className={styles.button}
                  variant={'contained'}
                  color={'primary'}
                  type={'button'}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!Boolean(commentValue) || loading}
                >
                  {loading ? <CircularProgress size={'24px'} /> : <strong>Додати</strong>}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default Postponed;
