import {Box, Button, Divider, Typography} from '@mui/material';
import styles from './styles.module.scss';
import AddIcon from '@mui/icons-material/Add';
import AdminForm from '../../common/dialogs/admin-form';
import React, {useState} from 'react';
import {useUserProvider} from '../../../contexts/UserContextContext';

const AdminLayout = ({children, title, isLoading = false, refetch = () => {}}) => {
  const [createAdminOpen, setCreateAdminOpen] = useState(false);
  const {user} = useUserProvider();

  return (
    <Box sx={{width: '100%', mt: 0, mb: 'auto'}}>
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: '24px',
          position: 'relative'
        }}
      >
        <Typography variant="h4">{title}</Typography>
        <Box sx={{display: 'flex', gap: '24px', flexShrink: '0'}}>
          {+user?.role.id < 3 && (
            <Button
              variant="outlined"
              sx={{flexShrink: 0, height: '48px', display: 'flex', gap: '8px', fontWeight: 700}}
              component={Button}
              type="button"
              onClick={() => {
                setCreateAdminOpen(true);
              }}
            >
              <AddIcon />
              <span>Створити запис</span>
            </Button>
          )}
        </Box>
        <Divider className={styles.divider} />
      </Box>
      <Box sx={{pt: '12px'}}>{children}</Box>
      <AdminForm open={createAdminOpen} setOpen={setCreateAdminOpen} />
    </Box>
  );
};

export default AdminLayout;
