import {Box, Card, Divider, FormControlLabel, Grid, Radio, Typography} from '@mui/material';
import {ImageCarousel} from 'components/common/imageCarousel';
import {ReactComponent as TrendUp} from 'svg-icons/trendUp.svg';
import {ReactComponent as TrendDown} from 'svg-icons/trend-down.svg';
import styles from './styles.module.scss';
import classNames from 'classnames';
import React from 'react';
import {PropertyLabelsType, PropertyListItemType} from 'types';
import {currencySymbols} from 'constants/index';
import ItemContacts from 'components/common/listItem/item-contacts';
import PropertyItemFooter from 'components/common/listItem/item-footer';
import PropertyAddress from 'components/common/property-address';
import PropertyHeader from 'components/common/property-header';
import {Controller} from 'react-hook-form';
import ListItemLabels from 'components/common/list-item-labels';
import {formatNumber} from 'helpers';
import NoImage from '../../../no-image';
import {PROPERTY_URL} from '../../../../../constants/routes';
import {useLocation} from 'react-router-dom';

const MergeListItem: React.FC<{
  item: PropertyListItemType;
  control: any;
}> = ({
  item: propertyItem,
  item: {
    address,
    media,
    price: {original, converted},
    sources,
    contacts,
    latest_publication,
    labels,
    offer_type
  },
  control
}) => {
  const location = useLocation();
  const priceLower = labels?.filter((item) => item.label.alias === PropertyLabelsType.PRICE_LOWER);
  const priceHigher = labels?.filter((item) => item.label.alias === PropertyLabelsType.PRICE_HIGHER);
  const isBase = location.pathname.includes(PROPERTY_URL);

  return (
    <>
      <Grid item className={styles.itemWrapper}>
        <Card sx={{bgcolor: 'background.default', px: '12px'}}>
          <Box className={styles.box}>
            <Box className={styles.row}>
              <ListItemLabels labels={labels} offerType={offer_type} isMergeItem={true} />
            </Box>
            <PropertyHeader
              sources={sources}
              title={propertyItem.title}
              type={propertyItem.estate_type}
              propertyId={propertyItem.id}
              contacts={propertyItem.contacts}
            />
            <Box className={classNames(styles.box, styles.auto)}>
              <Controller
                control={control}
                render={({field, fieldState, formState}) => {
                  return (
                    <FormControlLabel
                      {...field}
                      className={styles.radio}
                      checked={propertyItem.id.toString() === field.value?.toString()}
                      value={propertyItem.id}
                      control={<Radio />}
                      label=""
                    />
                  );
                }}
                name={`property_to_merge`}
              />
            </Box>
          </Box>
          <Divider sx={{mx: '-12px'}} />
          <Box className={styles.container}>
            <Box className={styles.gallery}>
              {media && media.length > 0 ? (
                <ImageCarousel nativeStyles={styles.imageItem} imageList={media.length ? media : []} />
              ) : (
                <NoImage />
              )}
            </Box>
            <Box className={styles.rightCol}>
              <Box className={classNames(styles.item, styles.large)}>
                <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
                <Box className={styles.row}>
                  <PropertyAddress address={address} showOnMap={false} />
                </Box>
              </Box>
              <Box className={classNames(styles.item, styles.large)}>
                <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
                <ItemContacts contacts={contacts} />
              </Box>
              <Box className={classNames(styles.item, styles.textRight)}>
                <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
                <Typography variant="body2" sx={{color: 'text.primary'}}>
                  Ціна:
                </Typography>
                <Box sx={{display: 'flex', gap: '8px', justifyContent: 'flex-end'}}>
                  {!!priceHigher?.length && <TrendUp />}
                  {!!priceLower?.length && <TrendDown />}
                  <Typography variant="body2" sx={{color: 'text.primary', fontWeight: 700}}>
                    {formatNumber(original.amount)} {currencySymbols[original.currency_code]}
                  </Typography>
                </Box>
                <Typography variant="body2">
                  ({formatNumber(converted.amount)} {currencySymbols[converted.currency_code]})
                </Typography>
              </Box>
              <Box className={styles.footer}>
                <Divider orientation={'vertical'} className={styles.divider} component={'div'} />
                <Divider className={classNames(styles.dividerBottom)} />
                <PropertyItemFooter
                  propertyId={propertyItem.id}
                  lastTimeContactedAt={propertyItem.last_time_contacted_at}
                  createdAt={propertyItem.created_at}
                  latestPublicationAt={latest_publication?.posted_at}
                  sources={sources}
                  contacts={contacts}
                  showLabels={false}
                  offerType={offer_type}
                  labels={labels}
                  isMergeItem
                  isBase={isBase}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default MergeListItem;
