import {Box, Grid, Link, Tooltip, Typography} from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import {ContactRole, PropertyLabels, RawEstateContacts} from 'types';
import {checkDateStatus, convertTimeAgo, formatCustomTime, formatDate, getHighestRolePriority} from 'helpers';
import ListItemLabels from '../../list-item-labels';
import classNames from 'classnames';
import {propertyContactRole} from '../../../../constants';

import {ReactComponent as TgIcon} from 'svg-icons/tg-icon.svg';

const BasePropertyItemFooter: React.FC<{
  propertyId: string;
  latestPublicationAt: string;
  lastTimeContactedAt: string | null;
  createdAt: string;
  contacts: RawEstateContacts[];
  showLabels?: boolean;
  labels: PropertyLabels[];
  offerType: string;
  isMergeItem?: boolean;
  parsedPropertyId: string;
  postLink: string;
}> = ({
  latestPublicationAt,
  lastTimeContactedAt,
  createdAt,
  propertyId,
  showLabels = true,
  labels,
  offerType,
  isMergeItem = false,
  contacts,
  parsedPropertyId,
  postLink
}) => {
  const timeColor = checkDateStatus(lastTimeContactedAt);
  const highPriorityContact = getHighestRolePriority(contacts);

  return (
    <Grid container spacing={4} className={styles.wrapper}>
      <Grid item className={styles.col}>
        {!!postLink && (
          <Link target="_blank" className={styles.link} href={postLink}>
            <TgIcon />
          </Link>
        )}

        {showLabels && <ListItemLabels labels={labels} offerType={offerType} />}
        {highPriorityContact.priority < 4 && (
          <Box className={classNames(styles.label)} sx={{bgcolor: `background.default`, color: 'text.primary'}}>
            У нас є:{' '}
            <Box
              component={'span'}
              className={classNames(styles.label)}
              sx={{color: +highPriorityContact.priority <= 1 ? 'text.green' : 'background.info'}}
            >
              {propertyContactRole[highPriorityContact.role as ContactRole]}
            </Box>
          </Box>
        )}
      </Grid>

      <Grid item className={styles.col}>
        {!!parsedPropertyId && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Парсинг #:
            </Typography>
            <Typography variant={'caption'}>{parsedPropertyId}</Typography>
          </Box>
        )}
        {latestPublicationAt && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Опубліковано:
            </Typography>
            <Tooltip disableInteractive title={formatCustomTime(latestPublicationAt)} arrow>
              <Typography variant={'caption'}>{convertTimeAgo(latestPublicationAt)}</Typography>
            </Tooltip>
          </Box>
        )}
        {!!lastTimeContactedAt && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Дата контакту:
            </Typography>
            <Typography variant={'caption'}>
              <Tooltip disableInteractive title={formatCustomTime(lastTimeContactedAt, 'DD.MM.YYYY')} arrow>
                <Typography variant={'caption'} className={styles[timeColor]}>
                  {formatDate(lastTimeContactedAt)}
                </Typography>
              </Tooltip>
            </Typography>
          </Box>
        )}
        {!isMergeItem && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Додано:
            </Typography>
            <Tooltip disableInteractive title={formatCustomTime(createdAt)} arrow>
              <Link component={'div'} variant={'caption'} sx={{color: 'text.success', textDecoration: 'none'}}>
                {convertTimeAgo(createdAt)}
              </Link>
            </Tooltip>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default BasePropertyItemFooter;
