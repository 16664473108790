import React, {useState} from 'react';
import {PropertyAdmin} from 'types';
import styles from './styles.module.scss';
import {Link, Tooltip} from '@mui/material';
import {updateAssignee} from 'api/properties';
import {useToastContext} from '../../../../../contexts/ToastContext';
import {useUserProvider} from '../../../../../contexts/UserContextContext';

const SelfAssignee: React.FC<{assignee: PropertyAdmin | null; propertyId: string; refetch: () => void}> = ({
  assignee,
  propertyId,
  refetch
}) => {
  const {setToastData, setOpenToast} = useToastContext();
  const [fromAssigneeId, setFromAssigneId] = useState<string | null>(assignee?.id || null);
  const {user} = useUserProvider();
  const tooltipTitle =
    fromAssigneeId === user?.id
      ? `Об'єкт вже заасайнен на ${assignee?.first_name} ${assignee?.last_name}`
      : `Заасайнити на ${user?.first_name || ''} ${user?.last_name || ''}`;

  const [loading, setLoading] = useState<boolean>(false);

  const updateAdmin = async () => {
    if (!user?.id || fromAssigneeId === user?.id) {
      return;
    }
    setLoading(true);
    const response = await updateAssignee(propertyId, {
      assignee: {
        from_id: fromAssigneeId,
        to_id: user?.id
      }
    });
    setToastData({
      toastBody: response?.message || '',
      toastTitle: response.success ? 'Успіх' : 'Помилка',
      toastType: response.success ? 'success' : 'error'
    });
    setLoading(false);
    if (response?.success) {
      setFromAssigneId(user?.id);
      refetch();
    }
    setOpenToast(true);
  };

  return (
    <Link
      className={styles.assigne}
      component={'button'}
      type={'button'}
      onClick={updateAdmin}
      disabled={loading || fromAssigneeId === user?.id}
    >
      <Tooltip disableInteractive title={tooltipTitle} placement="top" arrow>
        <span>Асайн на мене</span>
      </Tooltip>
    </Link>
  );
};

export default SelfAssignee;
