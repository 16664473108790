import {Box, Button, CircularProgress, Divider, Grid, Typography} from '@mui/material';
import React, {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {debounce, formatDateString, getNormalisedOptions} from 'helpers';
import {
  childrenAllowedStatus,
  currencySymbols,
  defaultPropertiesFilterValues,
  eastTypeNames,
  offerTypeNames,
  petsAllowedStatus,
  PREFERRED_CITY_KEY,
  propertyContactRole,
  propertyFiltersRoom,
  riverBankTypeNames
} from 'constants/index';
import styles from './styles.module.scss';
import classNames from 'classnames';
import FieldWidget from 'components/common/widgets';
import {CityCode, FormFields} from 'types';
import SourceSelect from 'components/common/widgets/sourceSelect';
import {getConvertedCurrency} from 'api/currency';
import {useFilterContext} from 'contexts/FiltersContext';
import useAssignee from 'hooks/useAssignee';
import {useLocation, useSearchParams} from 'react-router-dom';
import {PROPERTY_URL, PARSER_BASE_URL} from 'constants/routes';
import Streets from './streets';
import District from '../../../common/widgets/district';
import InputField from '../../../common/inputField';
import AutocompleteWithQuery from '../../../common/autocomplete-with-query';
import {getBuildingTypes, getResidentialComplex} from '../../../../api/filters';
import {useCitiesContext} from '../../../../contexts/CitiesContext';
import MetroStations from '../../../common/widgets/metro-stations';
import Subdistricts from '../../../common/widgets/subdistricts';

const defaultValues = {
  source_id: '',
  property_id: '',
  price_from: '',
  price_to: '',
  living_area_from: '',
  living_area_to: '',
  floor_from: '',
  floor_to: '',
  name: '',
  offer_type: 'rent',
  estate_type: 'apartment',
  street_name_autocomplete: '',
  riverbank: ''
};

const ParserFilters = ({isLoading = false, toggleCollapse}) => {
  const {cities} = useCitiesContext();

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    getValues,
    setError,
    reset,
    clearErrors,
    formState: {errors: formStateErrors}
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues
  });

  const citySelectValue = watch(FormFields.city_code);

  let [searchParams, setSearchParams] = useSearchParams();
  const {filterErrors, setFilters, filters} = useFilterContext();
  const {assignee: assignerList} = useAssignee(false);
  const location = useLocation();
  const isOwnerFilters = location.pathname.includes('all');
  const isParser = location.pathname.includes(PARSER_BASE_URL);

  const displayBuildingTypesSelect = watch('newly_built');

  const ignoredKeys = useMemo(() => {
    const keys = [
      'assignee_id',
      'last_time_contacted_at_from',
      'last_time_contacted_at_to',
      'adornment_price_from',
      'adornment_price_to',
      'source',
      'price_from',
      'price_to',
      'districts',
      'street_name_autocomplete',
      'newly_built',
      'building_types',
      'residential_complexes',
      'rooms',
      'living_area_from',
      'living_area_to',
      'floor_from',
      'floor_to',
      'children',
      'pets',
      'name',
      'role',
      'source_id',
      'contact',
      'name',
      'role',
      'phone_numbers',
      'author_id',
      'address.district'
    ];

    if (citySelectValue !== CityCode.KYIV) {
      keys.push('riverbank');
      keys.push('metro_stations');
      keys.push('subdistricts');
    }

    return keys;
  }, [citySelectValue]);

  const onApplyClick = async (data) => {
    try {
      const formData = new FormData();

      Object.entries(data).map(([key, value]) => {
        formData[key] = value;
      });

      const normalizedData = Object.keys(formData).reduce(
        (acc, key) => {
          if (acc[key] || ignoredKeys.includes(key) || !Boolean(key)) {
            return acc;
          }
          acc[key] = data[key];
          return acc;
        },
        {
          ...(data.last_time_contacted_at_from || data.last_time_contacted_at_to
            ? {
                last_time_contacted_at: {
                  ...(!!data.last_time_contacted_at_from && {
                    from: formatDateString(data.last_time_contacted_at_from)
                  }),
                  ...(!!data.last_time_contacted_at_to && {
                    to: formatDateString(data.last_time_contacted_at_to)
                  })
                }
              }
            : {}),
          source: {
            ...(!!data?.source && {
              type: data.source ?? ''
            }),
            ...(!!data?.source_id && {
              id: data.source_id ?? ''
            })
          },
          price: {
            ...(!!data?.price_from && {
              from: data.price_from ?? ''
            }),
            ...(!!data?.price_to && {
              to: data.price_to ?? ''
            })
          },
          ...(!!data?.address?.district && {
            districts: data?.address?.district.map((item) => item.value).sort((a, b) => a - b)
          }),
          ...(!!data?.newly_built && {
            newly_built: data.newly_built
          }),
          ...(!!data?.street_name_autocomplete && {
            street_name:
              typeof data.street_name_autocomplete === 'string'
                ? data.street_name_autocomplete
                : data.street_name_autocomplete?.label
          }),
          ...(!!data?.residential_complexes &&
            displayBuildingTypesSelect === '1' && {
              residential_complexes: data.residential_complexes.map((item) => item.value)
            }),
          ...(!!data?.building_types &&
            displayBuildingTypesSelect === '0' && {
              building_types: data.building_types.map((item) => item.value)
            }),
          features: {
            ...(data.rooms && {rooms: data.rooms.sort((a, b) => a - b)}),
            living_area: {
              ...(!!data?.living_area_from && {
                from: data?.living_area_from
              }),
              ...(!!data?.living_area_to && {
                to: data?.living_area_to
              })
            },
            floor: {
              ...(!!data?.floor_from && {
                from: data?.floor_from
              }),
              ...(!!data?.floor_to && {
                to: data?.floor_to
              })
            },
            ...(!!data?.children && {
              children: data?.children?.filter(Boolean)
            }),
            ...(!!data?.pets && {
              pets: data?.pets?.filter(Boolean)
            })
          },
          contact: {
            ...(!!data?.name && {
              name: data.name
            }),
            ...(!!data?.role && {
              roles: data.role
            }),
            ...(!!data?.phone_numbers && {
              phone_number: data.phone_numbers.replace(/\D/g, '')
            })
          },
          ...(!!data?.assignee_id && {
            assignee_id: data.assignee_id
          }),
          ...(!!data?.author_id && {
            author_id: data.author_id
          })
        }
      );
      toggleCollapse();
      setFilters(normalizedData);
      setSearchParams({cursor: ''});
    } catch (e) {
      console.error(e);
    }
  };

  const onAbortClick = () => {
    reset((values) => ({...defaultValues, city_code: localStorage.getItem(PREFERRED_CITY_KEY)}));
    setSearchParams({cursor: ''});
    setFilters({
      ...defaultPropertiesFilterValues,
      city_code: localStorage.getItem(PREFERRED_CITY_KEY) || cities[0].code
    });
    toggleCollapse();
  };

  const onPriceInputChange = debounce(async (name, value) => {
    if (!value) {
      setValue(`adornment_${name}`, '');
      return;
    }
    const response = await getConvertedCurrency({
      from_currency: 'UAH',
      to_currency: 'USD',
      amount: value
    });
    setValue(`adornment_${name}`, `${currencySymbols[response.currency_code]}${response.amount}`);
  }, 500);

  const onCityChange = (value) => {
    localStorage.setItem(PREFERRED_CITY_KEY, value);
  };

  useEffect(() => {
    if (!filterErrors) {
      return;
    }
    Object.entries(filterErrors).forEach(([key, value]) => {
      setError(key.toString(), {
        type: 'manual',
        message: Array.isArray(value) ? value[0] : value
      });
    });
  }, [filterErrors]);

  const preferredCity = localStorage.getItem(PREFERRED_CITY_KEY);

  useEffect(() => {
    if (!!preferredCity) {
      setFilters({...defaultPropertiesFilterValues, city_code: preferredCity});
      setValue('city_code', preferredCity);
      return;
    }

    if (cities[0]?.code) {
      setFilters({...defaultPropertiesFilterValues, city_code: cities[0]?.code});
      setValue('city_code', cities[0]?.code);
    }
  }, [cities, preferredCity, setFilters, setValue]);

  return (
    <form className={styles.form}>
      <Grid container spacing={2} sx={{flexGrow: 1, py: '24px'}}>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Інформація про пропозицію</Typography>
        </Grid>
        <SourceSelect control={control} register={register} gridSize={3} />
        <FieldWidget control={control} register={register} filedName={FormFields.source_id} gridSize={3} />
        <Grid item xs={3}>
          <InputField
            control={control}
            onChange={() => {}}
            name={FormFields.property_id}
            size={'medium'}
            label={'ID обʼєкту нерухомості'}
            placeholder={'ID обʼєкту'}
            rules={{
              pattern: {
                value: /^\d+$/,
                message: 'Поле повинно бути цілим числом.'
              }
            }}
          />
        </Grid>
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.estate_type}
          gridSize={3}
          items={getNormalisedOptions(eastTypeNames)}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.price_from}
          gridSize={3}
          onChange={(name, value) => {
            if (value && parseInt(value, 10) > 500000) {
              setError(FormFields.price_from, {
                type: 'manual',
                message: 'Поле Ціна Від не повинно бути більше ніж 500000.'
              });
              return;
            }
            clearErrors(FormFields.price_from);
            onPriceInputChange(name, value);
          }}
          showEndAdornment={true}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            },
            validate: (value) => {
              if (parseInt(value, 10) > 500000) {
                return 'Поле Ціна Від не повинно бути більше ніж 500000.';
              }
              return true;
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.price_to}
          gridSize={3}
          onChange={(name, value) => {
            if (value && parseInt(value, 10) > 500000) {
              setError(FormFields.price_to, {
                type: 'manual',
                message: 'Поле Ціна До не повинно бути більше ніж 500000.'
              });
              return;
            }
            clearErrors(FormFields.price_to);
            onPriceInputChange(name, value);
          }}
          showEndAdornment={true}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            },
            validate: (value) => {
              if (parseInt(value, 10) > 500000) {
                return 'Поле Ціна До не повинно бути більше ніж 500000.';
              }
              return true;
            }
          }}
        />

        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.offer_type}
          gridSize={3}
          items={getNormalisedOptions(offerTypeNames)}
        />
        <Grid xs={12} item>
          <Divider sx={{py: '12px', mb: '12px'}} />
        </Grid>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Інформація про обʼєкт</Typography>
        </Grid>
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.city_code}
          gridSize={3}
          items={cities.map((item) => ({
            value: item.code,
            label: item.name
          }))}
          onChange={(event) => {
            onCityChange(event.target.value);
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          items={getNormalisedOptions(riverBankTypeNames)}
          filedName={FormFields.riverbank}
          gridSize={3}
          disabled={citySelectValue !== CityCode.KYIV}
        />
        <MetroStations
          control={control}
          register={register}
          gridSize={3}
          multiple={true}
          citySelectValue={citySelectValue}
          filedName={FormFields.metro_stations}
        />
        <Subdistricts
          control={control}
          register={register}
          gridSize={3}
          multiple={true}
          citySelectValue={citySelectValue}
          filedName={FormFields.subdistricts}
        />
        <District
          control={control}
          register={register}
          defaultItems={[{label: '', value: ''}]}
          gridSize={3}
          multiple={true}
          required={false}
          label={'Район'}
          enableTrigger={filters?.city_code}
        />
        <Streets control={control} register={register} enableTrigger={filters?.city_code} />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.newly_built}
          gridSize={3}
          items={[
            {label: 'Новобудова', value: '1'},
            {label: 'Вторинка', value: '0'}
          ]}
        />
        {displayBuildingTypesSelect === '1' && (
          <AutocompleteWithQuery
            control={control}
            register={register}
            fieldName={FormFields.residential_complexes}
            gridSize={3}
            className={classNames(styles.hide, {
              [styles.show]: displayBuildingTypesSelect === '1'
            })}
            multiple={true}
            queryFn={getResidentialComplex}
            queryKey={'locations/residentialComplex'}
            getCityCodeValue={() => {
              return getValues('city_code');
            }}
          />
        )}
        {displayBuildingTypesSelect === '0' && (
          <AutocompleteWithQuery
            control={control}
            register={register}
            fieldName={FormFields.building_types}
            gridSize={3}
            className={classNames(styles.hide, {
              [styles.show]: displayBuildingTypesSelect === '0'
            })}
            multiple={true}
            queryFn={getBuildingTypes}
            queryKey={'locations/building-type'}
            getCityCodeValue={() => {
              return getValues('city_code');
            }}
          />
        )}
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.rooms}
          gridSize={3}
          items={propertyFiltersRoom}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.living_area_from}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            },
            validate: {
              requiredWithTo: (value) =>
                !value && watch(FormFields.living_area_to) ? 'Поле "Від" обов\'язкове, якщо задано поле "До".' : true,
              minValue: (value) => (value && Number(value) < 1 ? 'Поле повинно бути принаймні 1.' : true)
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.living_area_to}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            },
            validate: {
              minValue: (value) => (value && Number(value) < 1 ? 'Поле повинно бути принаймні 1.' : true)
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.floor_from}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            },
            validate: {
              requiredWithTo: (value) =>
                !value && watch(FormFields.floor_to) ? 'Поле "Від" обов\'язкове, якщо задано поле "До".' : true,
              minValue: (value) => (value && Number(value) < 1 ? 'Поле повинно бути принаймні 1.' : true)
            }
          }}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.floor_to}
          gridSize={1.5}
          rules={{
            pattern: {
              value: /^\d+$/,
              message: 'Поле повинно бути цілим числом.'
            }
          }}
        />

        {!isOwnerFilters && isParser && (
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields.assignee_id}
            gridSize={3}
            items={assignerList.map((item) => ({
              label: `${item.first_name} ${item.last_name}`,
              value: item.id
            }))}
          />
        )}
        <Grid xs={12} item>
          <Divider sx={{py: '12px', mb: '12px'}} />
        </Grid>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Контактна інформація</Typography>
        </Grid>
        <FieldWidget control={control} register={register} filedName={FormFields.name} gridSize={3} />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.role}
          gridSize={3}
          items={getNormalisedOptions(propertyContactRole)}
          multiple={true}
        />
        <FieldWidget control={control} register={register} filedName={FormFields.phone_numbers} gridSize={3} />
        {!isParser && (
          <Grid xs={12} item>
            <Divider sx={{py: '12px', mb: '12px'}} />
          </Grid>
        )}
        <Grid xs={12} item>
          <Typography variant={'body1'}>Основна інформація</Typography>
        </Grid>
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.last_time_contacted_at_from}
          gridSize={3}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.last_time_contacted_at_to}
          gridSize={3}
        />
        {!isParser && (
          <FieldWidget
            control={control}
            register={register}
            filedName={FormFields.author_id}
            gridSize={3}
            items={assignerList.map((item) => ({
              label: `${item.first_name} ${item.last_name}`,
              value: item.id
            }))}
          />
        )}
        <Grid xs={12} item>
          <Divider sx={{py: '12px', mb: '12px'}} />
        </Grid>
        <Grid xs={12} item>
          <Typography variant={'body1'}>Додаткова інформація</Typography>
        </Grid>
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.children}
          gridSize={3}
          items={getNormalisedOptions(childrenAllowedStatus)}
          multiple={true}
        />
        <FieldWidget
          control={control}
          register={register}
          filedName={FormFields.pets}
          gridSize={3}
          items={getNormalisedOptions(petsAllowedStatus)}
          multiple={true}
        />
      </Grid>
      <Box className={styles.btnWrapper} sx={{bgcolor: 'background.paper'}}>
        <Button variant={'contained'} color={'dark'} type={'button'} className={styles.btn} onClick={onAbortClick}>
          Скинути всі
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          type={'submit'}
          onClick={handleSubmit(onApplyClick)}
          disabled={Object.keys(formStateErrors).length > 0 || isLoading}
          className={styles.btn}
        >
          {isLoading ? <CircularProgress size={'20px'} /> : <>Застосувати</>}
        </Button>
      </Box>
    </form>
  );
};
export default ParserFilters;
