import React, {useMemo} from 'react';
import {FormFields} from '../../../../../types';
import FieldWidget from '../../../../common/widgets';
import {useQuery} from 'react-query';
import {API_ROUTES} from '../../../../../constants';
import {debounce} from '../../../../../helpers';
import {getStreetStreets} from '../../../../../api/filters';

const Streets: React.FC<{control: any; register: any; enableTrigger: string}> = ({
  control,
  register,
  enableTrigger
}) => {
  const [name, setName] = React.useState('');

  const debouncedGetStreets = useMemo(() => {
    return debounce(
      (name: string, callback: (result: any) => void) =>
        callback(getStreetStreets({street_name: name, city_code: enableTrigger})),
      300
    );
  }, [enableTrigger]);

  const {data: streets = [], isLoading: isStreets} = useQuery(
    [API_ROUTES.STREETS, name, enableTrigger],
    () =>
      new Promise((resolve) => {
        debouncedGetStreets(name, resolve);
      }),
    {
      retry: false,
      enabled: !!enableTrigger
    }
  );

  const onChange = debounce((name: string, value: string) => {
    setName(value);
  }, 500);

  return (
    <FieldWidget
      control={control}
      register={register}
      filedName={FormFields.street_name_autocomplete}
      gridSize={3}
      items={
        Array.isArray(streets) && streets.length > 0
          ? streets.map((item) => ({
              value: item.street_type,
              label: item.street_name
            }))
          : []
      }
      multiple={false}
      rules={{}}
      onChange={onChange}
    />
  );
};

export default Streets;
