import {FormControl, FormHelperText, MenuItem, Select, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {Controller} from 'react-hook-form';

const SelectDropdown = ({
  label,
  name,
  onChange = () => {},
  items,
  defaultValue = '',
  control,
  size,
  placeholder = '',
  register,
  disabled = false,
  sx = {},
  multiple = false,
  rules = {}
}) => {
  return (
    <FormControl fullWidth className={styles[size]} defaultValue={'10'}>
      <Typography variant="caption" gutterBottom>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        disabled={disabled}
        render={({field, fieldState, formState}) => {
          const getErrorMessage = (fieldName, errors) => {
            return fieldName.split('.').reduce((acc, key) => acc?.[key] || null, errors);
          };
          const valueToFilter = Array.isArray(field?.value) ? field?.value : [field?.value];

          const multiplyValue = valueToFilter.filter((item) => Boolean(item)).length
            ? valueToFilter.filter((item) => Boolean(item))
            : [''];

          const errorMessage = getErrorMessage(name, formState.errors);

          return (
            <>
              <Select
                {...field}
                error={!!errorMessage}
                multiple={multiple}
                labelId={`${name}-label`}
                displayEmpty
                inputProps={{'aria-label': 'Without label'}}
                onChange={(event) => {
                  field.onChange(event);
                  onChange(event);
                }}
                size="small"
                value={multiple ? multiplyValue : field.value?.value || field?.value || ''}
                sx={{borderRadius: '8px', fontSize: '14px', ...sx}}
                className={disabled ? styles.disabled : ''}
              >
                <MenuItem disabled value={''}>
                  <em>{placeholder}</em>
                </MenuItem>

                {items?.map((item, index) => (
                  <MenuItem key={`item_${index}`} value={item?.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {errorMessage && (
                <FormHelperText sx={{color: 'error.main'}} id="outlined-weight-helper-text">
                  {errorMessage['0'] || errorMessage?.message || errorMessage}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default SelectDropdown;
