import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import classNames from 'classnames';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import styles from './styles.module.scss';
import {ReactComponent as SearchIcon} from '../../../svg-icons/search.svg';
import {ReactComponent as CloseIcon} from '../../../svg-icons/close.svg';
import {ReactComponent as CheckIcon} from '../../../svg-icons/circle-check.svg';
import {useFilterContext} from '../../../contexts/FiltersContext';
import {defaultPropertiesFilterValues, PREFERRED_CITY_KEY, urlPattern} from '../../../constants';
import {useParams} from 'react-router-dom';

interface FormValues {
  filterKey: 'phone_numbers' | 'property_id' | 'post_link' | 'source_id' | 'source_link';
  search: string;
}

const SearchBarWithDropdown: React.FC<{isBase: boolean}> = ({isBase}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {setFilters} = useFilterContext();
  const {filter: filterName = ''} = useParams();
  const {filters} = useFilterContext();

  const {control, handleSubmit, reset, watch, setValue} = useForm<FormValues>({
    defaultValues: {
      filterKey: 'property_id',
      search: ''
    }
  });

  const startAdornmentItems = [
    {label: 'ID', value: 'property_id'},
    {label: 'Номер', value: 'phone_numbers'},
    {label: 'ID ресурсу', value: 'source_id'},
    {label: 'URL ресурсу', value: 'source_link'}
  ];

  const onApplyClick: SubmitHandler<FormValues> = async (data) => {
    if (Object.keys(data).length === 0) {
      return;
    }

    setIsLoading(true);

    try {
      let payload = {};

      if (data.filterKey === 'phone_numbers') {
        payload = {[`contact[phone_number]`]: data.search};
      } else if (data.filterKey === 'property_id') {
        payload = {[`property_id`]: data.search};
      } else if (data.filterKey === 'post_link') {
        payload = {[`post_link`]: data.search};
      } else if (data.filterKey === 'source_id') {
        payload = {source: {id: data.search}};
      } else if (data.filterKey === 'source_link') {
        payload = {source: {link: data.search}};
      } else {
        payload = {search: data.search, filterKey: data.filterKey};
      }
      setFilters({...payload, city_code: localStorage.getItem(PREFERRED_CITY_KEY)});
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    reset();
    setFilters({...defaultPropertiesFilterValues, city_code: localStorage.getItem(PREFERRED_CITY_KEY)});
  };

  useEffect(() => {
    handleReset();
  }, [filterName]);

  const currentCurrency = watch('filterKey');

  const validationRules = useMemo(() => {
    switch (currentCurrency) {
      case 'phone_numbers':
        return {
          pattern: {
            value: /^[\d\s()+-]+$/,
            message: 'Поле повинно бути номером телефону.'
          }
        };
      case 'property_id':
        return {
          pattern: {
            value: /^\d+$/,
            message: 'Поле повинно бути цілим числом.'
          }
        };
      case 'post_link':
        return {
          pattern: {
            value: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
            message: 'Поле повинно бути URL-адресою.'
          }
        };
      case 'source_id':
        return {
          pattern: {
            value: /^(?!\s+$).+$/,
            message: 'Мають бути тільки цифри та літери'
          }
        };
      case 'source_link':
        return {
          pattern: {
            value: urlPattern,
            message: 'Поле повинно бути URL-адресою.'
          }
        };
    }
  }, [currentCurrency]);

  const StartAdornment = useMemo(() => {
    if (isBase) {
      startAdornmentItems.push({label: 'Tg URL', value: 'post_link'});
    }
    return (
      <InputAdornment position="start" className={classNames(styles.wrapper)} sx={{maxHeight: 'unset', height: '100%'}}>
        <Controller
          control={control}
          name="filterKey"
          render={({field}) => (
            <Select {...field} variant="filled">
              {startAdornmentItems.map((item, index) => (
                <MenuItem
                  key={`item_${index}`}
                  value={item.value}
                  sx={{
                    maxWidth: '85px',
                    padding: '6px 8px',
                    borderRadius: '0 !important',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: '12px',
                    '&.Mui-selected': {
                      backgroundColor: 'unset',
                      color: 'background.info'
                    }
                  }}
                >
                  {item.label}
                  <ListItemIcon sx={{minWidth: 'unset !important'}}>
                    {item.value === field.value && <CheckIcon />}
                  </ListItemIcon>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </InputAdornment>
    );
  }, [control, startAdornmentItems, isBase]);

  const EndAdornment = useMemo(
    () => (
      <InputAdornment position="end" className={classNames(styles.wrapper)}>
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
            <button type="button" onClick={handleReset} className={classNames(styles.closeButton)}>
              <CloseIcon />
            </button>

            <Box className={classNames(styles.divider)} sx={{bgcolor: 'dark.secondary'}} />

            <button type="button" onClick={handleSubmit(onApplyClick)} className={classNames(styles.searchButton)}>
              <SearchIcon />
            </button>
          </Box>
        )}
      </InputAdornment>
    ),
    [handleSubmit, isLoading]
  );

  return (
    <form onSubmit={handleSubmit(onApplyClick)}>
      <FormControl fullWidth className={classNames(styles.large)}>
        <Controller
          control={control}
          name="search"
          rules={validationRules}
          render={({field, fieldState}) => {
            const errorMessage = fieldState.error?.message;

            return (
              <>
                <TextField
                  error={!!errorMessage}
                  placeholder="Пошук"
                  InputProps={{
                    startAdornment: StartAdornment,
                    endAdornment: EndAdornment
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleSubmit(onApplyClick)();
                  }}
                  {...field}
                />
                {errorMessage && <FormHelperText sx={{color: 'error.main'}}>{errorMessage}</FormHelperText>}
              </>
            );
          }}
        />
      </FormControl>
    </form>
  );
};

export default SearchBarWithDropdown;
