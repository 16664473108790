import {EstateCity} from '../types';
import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {getCities} from '../api/filters';
import {useUserProvider} from './UserContextContext';
import {PREFERRED_CITY_KEY} from '../constants';

interface CitiesContextType {
  cities: EstateCity[];
  setCities: Dispatch<SetStateAction<EstateCity[]>>;
}

const CitiesContext = createContext<CitiesContextType>({
  cities: [],
  setCities: () => {}
});

export const CitiesContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {user} = useUserProvider();
  const {data: availableCites = [], isLoading: isCitiesLoading} = useQuery<EstateCity[]>(
    ['locations/cities', user?.id],
    getCities,
    {enabled: !!user?.id}
  );

  const [cities, setCities] = useState<EstateCity[]>([]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const preferredCity = localStorage.getItem(PREFERRED_CITY_KEY) || null;

    if (!preferredCity && availableCites[0]?.code) {
      localStorage.setItem(PREFERRED_CITY_KEY, availableCites[0]?.code);
    }
    setCities(availableCites);
  }, [availableCites, user]);

  return <CitiesContext.Provider value={{cities, setCities}}>{children}</CitiesContext.Provider>;
};

export const useCitiesContext = () => {
  const context = useContext(CitiesContext);
  if (!context) {
    throw new Error('useCitiesContext must be used within a ContextProvider');
  }
  return context;
};
