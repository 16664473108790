import {Grid, Typography} from '@mui/material';
import FieldWidget from '../../widgets';
import {FormFields} from 'types';
import dayjs from 'dayjs';
import React from 'react';

const MainInfoParser: React.FC<{control: any; register: any; disabled: boolean}> = ({control, register, disabled}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{fontWeight: 700}}>
          Основна інформація
        </Typography>
      </Grid>
      <FieldWidget
        control={control}
        register={register}
        filedName={FormFields.last_time_contacted_at}
        maxDate={dayjs(new Date())}
      />
    </>
  );
};

export default MainInfoParser;
