import {EstateSource, EstateType, RawEstateContacts} from 'types';
import {Box, Button, Link, Tooltip, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as BuildingIcon} from 'svg-icons/building.svg';
import {OpenInNew as OpenInNewIcon} from '@mui/icons-material';
import React from 'react';
import {getHighestRolePriority} from '../../../helpers';
const PropertyHeaderParser: React.FC<{
  type: EstateType;
  title: string;
  sources: EstateSource[];
  propertyId: string;
  contacts: RawEstateContacts[];
}> = ({type, title, sources, propertyId, contacts}) => {
  const highPriorityContact = getHighestRolePriority(contacts);
  const primarySource = sources.find((source) => source.primary);

  return (
    <>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
          <Box sx={{color: +highPriorityContact.priority <= 1 ? 'text.green' : 'background.info'}}>
            <strong>{propertyId}</strong>
          </Box>
          {type === EstateType.APARTMENT && (
            <Button sx={{padding: 0, minWidth: '20px', height: '20px'}}>
              <Tooltip disableInteractive title={'Квартира'} placement="top" arrow>
                <BuildingIcon />
              </Tooltip>
            </Button>
          )}
          <Typography variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
            {title || 'Заголовок'}
          </Typography>
          {!!primarySource?.link && (
            <Link target="_blank" className={styles.link} href={primarySource.link}>
              <OpenInNewIcon width={20} height={20} />
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PropertyHeaderParser;
