import {EstateSource, EstateType, RawEstateContacts} from 'types';
import {Box, Button, Collapse, Link, Tooltip, Typography} from '@mui/material';
import styles from './styles.module.scss';
import {ReactComponent as BuildingIcon} from 'svg-icons/building.svg';
import {ReactComponent as LinkIcon} from 'svg-icons/link-icon.svg';
import React, {useState} from 'react';
import {getHighestRolePriority} from '../../../helpers';
import ClipboardCopy from '../clipboardCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
const PropertyHeader: React.FC<{
  type: EstateType;
  title: string;
  sources: EstateSource[];
  propertyId: string;
  contacts: RawEstateContacts[];
}> = ({type, title, sources, propertyId, contacts}) => {
  const highPriorityContact = getHighestRolePriority(contacts);
  const [open, setOpen] = useState(false);
  const onExpandClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
          <Box sx={{color: +highPriorityContact.priority <= 1 ? 'text.green' : 'background.info'}}>
            <strong>{propertyId}</strong>
          </Box>
          {type === EstateType.APARTMENT && (
            <Button sx={{padding: 0, minWidth: '20px', height: '20px'}}>
              <Tooltip disableInteractive title={'Квартира'} placement="top" arrow>
                <BuildingIcon />
              </Tooltip>
            </Button>
          )}
          <Typography variant="h6" sx={{display: 'flex', alignItems: 'center'}}>
            {title || 'Заголовок'}
          </Typography>
          <Button
            className={classNames(styles.button, {[styles.rotate]: open})}
            sx={{color: 'background.info'}}
            onClick={() => {
              onExpandClick();
            }}
            disabled={!sources?.length}
          >
            Показати джерела <ExpandMoreIcon width={12} height={12} />
          </Button>
        </Box>
        {!!sources?.length && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            {sources.map((source, index) => {
              return (
                <>
                  {!!source?.source_id || !!source?.link ? (
                    <Box className={styles.adress} content={'ul'} key={index}>
                      <li className={classNames({[styles.main]: source.primary})}>
                        {!!source.source_id && (
                          <>
                            <Box sx={{color: 'text.primary'}}>ID:</Box>
                            <Box>{source.source_id}</Box>
                            <ClipboardCopy value={source.source_id} />
                          </>
                        )}
                        {!!source?.link && (
                          <>
                            <Link
                              href={source.link}
                              target={'_blank'}
                              rel="noopener noreferrer"
                              className={styles.linkCopy}
                            >
                              {source.link}
                              <LinkIcon />
                            </Link>
                          </>
                        )}
                      </li>
                      <span></span>
                    </Box>
                  ) : null}
                </>
              );
            })}
          </Collapse>
        )}
      </Box>
    </>
  );
};

export default PropertyHeader;
