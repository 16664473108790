import React, {useMemo, useState} from 'react';
import {Box, Button, ClickAwayListener, FormHelperText, Grid, IconButton, Input, Tooltip} from '@mui/material';
import styles from '../styles.module.scss';
import FieldWidget from '../index';
import {FormFields, ISelectItem} from 'types';
import {debounce, getNormalisedOptions} from 'helpers';
import {API_ROUTES, streetTypeNames, urlPattern} from 'constants/index';
import classNames from 'classnames';
import {ReactComponent as LinkIcon} from 'svg-icons/link.svg';
import {getStreetStreets} from '../../../../api/filters';
import {useQuery} from 'react-query';
import cityCode from '../../../Layouts/city-code';

const Street: React.FC<{
  control: any;
  register: any;
  setValue: (name: string, value: string) => void;
  defaultGeolink?: string;
  getValues: (name: string) => string;
  errors: any;
  setError: any;
  clearErrors: any;
  defaultItems: ISelectItem[];
  enableTrigger: string;
}> = ({
  control,
  register,
  setValue,
  defaultGeolink = '',
  getValues = () => {},
  errors,
  setError,
  clearErrors,
  defaultItems,
  enableTrigger
}) => {
  const [open, setOpen] = React.useState(false);
  const [defaultValue, setDefaultValue] = useState(defaultGeolink);
  const [name, setName] = React.useState('');

  const onIconClick = () => {
    setOpen(true);
  };

  const validateURL = (inputValue: string) => {
    if (!urlPattern.test(inputValue) && inputValue !== '') {
      setError('address.geo_link', {
        type: 'manual',
        message: 'Поле має бути дійсною URL-адресою.'
      });
      return;
    }
    if (errors?.address?.geo_link?.message) {
      clearErrors('address.geo_link');
    }
  };

  const debouncedGetStreets = useMemo(() => {
    return debounce(
      (name: string, cityCode: string, callback: (result: any) => void) =>
        callback(getStreetStreets({street_name: name, city_code: cityCode})),
      300
    );
  }, []);

  const {data: streets = [], isLoading: isStreets} = useQuery(
    [API_ROUTES.STREETS, name, enableTrigger],
    () =>
      new Promise((resolve) => {
        debouncedGetStreets(name, enableTrigger, resolve);
      }),
    {
      retry: false,
      enabled: !!enableTrigger
    }
  );
  const onChange = debounce((name: string, value: string) => {
    setName(value);
  }, 500);

  const onConfirmClick = () => {
    const inputValue = getValues(FormFields['address.geo_link']) || '';
    if (!urlPattern.test(inputValue) && inputValue !== '') {
      setError('address.geo_link', {
        type: 'manual',
        message: 'Поле має бути дійсною URL-адресою.'
      });
      return;
    }
    if (!inputValue) {
      clearErrors('address.geo_link');
    }
    setValue(FormFields['address.geo_link'], inputValue || '');
    setDefaultValue(inputValue || '');
    setOpen(!open);
  };

  const onClickOutside = () => {
    const fieldValue = getValues(FormFields['address.geo_link']) || '';

    if (fieldValue !== defaultValue) {
      if (urlPattern.test(defaultValue) || defaultValue === '') {
        clearErrors('address.geo_link');
      }
      setValue(FormFields['address.geo_link'], defaultValue);
    }

    setOpen(false);
  };

  return (
    <Grid item xs={6} className={classNames(styles.row, styles.relative)}>
      <FieldWidget
        disabled={!enableTrigger}
        control={control}
        register={register}
        filedName={FormFields['address.street_name']}
        gridSize={12}
        items={
          Array.isArray(streets) && streets.length
            ? streets.map((item) => ({
                label: item.street_name,
                value: item.city_code
              }))
            : defaultItems
        }
        startAdornmentItems={getNormalisedOptions(streetTypeNames)}
        rules={{
          required: "Поле обов'язкове."
        }}
        onChange={onChange}
      />
      <Box
        className={styles.button}
        sx={{
          bgcolor: errors?.address?.geo_link?.message ? 'background.error' : open ? 'background.info' : 'transparent'
        }}
      >
        <ClickAwayListener onClickAway={onClickOutside}>
          <div>
            <IconButton type="button" onClick={onIconClick}>
              <Box sx={{color: open ? 'background.default' : defaultValue ? 'background.info' : 'text.customGray'}}>
                <LinkIcon />
              </Box>
            </IconButton>
            <Box
              className={classNames(styles.input, styles.wrap, {[styles.isOpen]: open})}
              sx={{
                borderColor: errors?.address?.geo_link?.message ? 'background.error' : 'dark.secondary',
                bgcolor: 'dark.gray'
              }}
            >
              <Box className={classNames(styles.flex)}>
                <Input
                  error={!!errors?.address?.geo_link?.message}
                  sx={{bgcolor: 'background.default', borderColor: 'background.info'}}
                  placeholder={'Посилання на локацію'}
                  name={FormFields['address.geo_link']}
                  {...register(FormFields['address.geo_link'], {
                    required: "Поле обов'язкове.",
                    pattern: {
                      value: urlPattern,
                      message: 'Поле має бути дійсною URL-адресою.'
                    }
                  })}
                  onChange={(e) => validateURL(e.target.value)}
                  defaultValue={defaultValue}
                />
                <Button variant={'contained'} type={'button'} onClick={onConfirmClick}>
                  <strong>OK</strong>
                </Button>
              </Box>
              {errors?.address?.geo_link?.message && (
                <FormHelperText sx={{color: 'error.main'}}>
                  <>{errors?.address?.geo_link?.message}</>
                </FormHelperText>
              )}
            </Box>
          </div>
        </ClickAwayListener>
      </Box>
    </Grid>
  );
};

export default Street;
