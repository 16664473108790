import React, {useEffect} from 'react';
import {Grid} from '@mui/material';
import styles from './styles.module.scss';
import SelectDropdown from '../../common/selectDropdown';
import {PREFERRED_CITY_KEY} from '../../../constants';
import {useCitiesContext} from '../../../contexts/CitiesContext';
import {useForm} from 'react-hook-form';
import {useFilterContext} from '../../../contexts/FiltersContext';
import {FormFields} from '../../../types';

const CityCodeSelect = () => {
  const {control, register, setValue} = useForm();
  const {cities} = useCitiesContext();
  const {filters, setFilters} = useFilterContext();

  const onCityChange = (value: string) => {
    localStorage.setItem(PREFERRED_CITY_KEY, value);
    setFilters({...filters, city_code: value});
  };

  useEffect(() => {
    setValue('city_code', localStorage.getItem(PREFERRED_CITY_KEY));
  }, [filters, setValue, cities]);

  return (
    <Grid component={'form'} item xs={12} className={styles.row}>
      <SelectDropdown
        control={control}
        register={register}
        label={''}
        name={FormFields.city_code}
        placeholder={''}
        items={cities.map((item) => ({
          value: item.code,
          label: item.name
        }))}
        //@ts-ignore
        onChange={(event) => {
          onCityChange(event.target.value);
        }}
        size={'small'}
      />
    </Grid>
  );
};

export default CityCodeSelect;
