import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {defaultPropertiesFilterValues, PREFERRED_CITY_KEY} from '../constants';
import {useCitiesContext} from './CitiesContext';

interface FilterContextType {
  filters: Record<string, any>;
  setFilters: Dispatch<SetStateAction<Record<string, any>>>;
  filterErrors: Record<string, any>;
  setFilterErrors: Dispatch<SetStateAction<Record<string, any>>>;
}

const LoadingContext = createContext<FilterContextType>({
  filters: defaultPropertiesFilterValues,
  setFilters: () => {},
  filterErrors: {},
  setFilterErrors: () => {}
});

export const FilterContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const preferredCity = localStorage.getItem(PREFERRED_CITY_KEY);
  const {cities} = useCitiesContext();

  const [filters, setFilters] = useState<Record<string, any>>({
    ...defaultPropertiesFilterValues,
    city_code: preferredCity || cities[0]?.code
  });
  const [filterErrors, setFilterErrors] = useState<Record<string, any>>({});

  useEffect(() => {}, []);

  return (
    <LoadingContext.Provider value={{filters, setFilters, filterErrors, setFilterErrors}}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useFilterContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
