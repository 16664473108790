import React, {useEffect, useState} from 'react';

import {
  ToggleOffOutlined as ToggleOffOutlinedIcon,
  AccountCircle as AccountCircleIcon,
  ModeNight as ModeNightIcon,
  LightMode as LightModeIcon
} from '@mui/icons-material';
import {Toolbar, AppBar, IconButton, Box, Typography, Menu, Container, Tooltip, MenuItem} from '@mui/material';
import {SIGN_IN_BASE_URL, SIGN_OUT_BASE_URL} from '../../constants/routes';
import {useTheme} from 'hooks/useTheme';
import {logout} from 'api/auth';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as Logo} from 'svg-icons/logo.svg';
import {useUserProvider} from '../../contexts/UserContextContext';
import CityCodeSelect from './city-code';
import {PREFERRED_CITY_KEY} from '../../constants';

const Header = () => {
  const navigate = useNavigate();

  const {theme, setTheme} = useTheme();
  const {user, setUser} = useUserProvider();

  const profilePages = [];

  if (!user) {
    profilePages.push({link: SIGN_IN_BASE_URL, name: 'Увійти'});
  } else {
    profilePages.push({link: SIGN_OUT_BASE_URL, name: 'Вийти'});
  }

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = async () => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem(PREFERRED_CITY_KEY);
      const response = await logout();
      setUser(null);
      navigate(SIGN_IN_BASE_URL);
    } catch (error) {}
  };

  return (
    <AppBar position="static" color="default" elevation={1} sx={{width: '100%'}}>
      <Container maxWidth="xl" sx={{px: {xs: 0, sm: 3}}}>
        <Toolbar disableGutters>
          <Logo />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none'
            }}
          ></Typography>
          <Box sx={{flexGrow: 0, display: 'flex', gap: '12px'}}>
            {user && <CityCodeSelect />}

            <Tooltip disableInteractive title="Профіль користувача" arrow>
              <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                <AccountCircleIcon fontSize={'large'} />
              </IconButton>
            </Tooltip>

            {user && (
              <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={() => {
                  setAnchorElUser(null);
                }}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Вийти</Typography>
                </MenuItem>
              </Menu>
            )}

            <IconButton aria-label={'Тема'} color="inherit">
              <ToggleOffOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label={'Тема'}
              onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
              color="inherit"
            >
              {theme === 'dark' ? <LightModeIcon /> : <ModeNightIcon />}
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
