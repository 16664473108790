import {Box, Grid, Link, Tooltip, Typography} from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import {ContactRole, EstateSource, PropertyLabels, RawEstateContacts} from 'types';
import {propertyContactRole, propertySourceNames} from 'constants/index';
import {
  checkDateStatus,
  convertTimeAgo,
  formatCustomTime,
  formatDate,
  formatTimestamp,
  getHighestRolePriority
} from 'helpers';
import ListItemLabels from '../../list-item-labels';
import classNames from 'classnames';

const PropertyItemFooter: React.FC<{
  propertyId: string;
  sources: EstateSource[];
  latestPublicationAt: string;
  lastTimeContactedAt: string | null;
  createdAt: string;
  contacts: RawEstateContacts[];
  showLabels?: boolean;
  labels: PropertyLabels[];
  offerType: string;
  isMergeItem?: boolean;
  isBase: boolean;
}> = ({
  sources,
  latestPublicationAt,
  lastTimeContactedAt,
  createdAt,
  propertyId,
  showLabels = true,
  labels,
  offerType,
  isMergeItem = false,
  isBase,
  contacts
}) => {
  const source = Array.isArray(sources) ? sources.find((source) => source.primary) : sources;
  const timeColor = checkDateStatus(lastTimeContactedAt);
  const highPriorityContact = getHighestRolePriority(contacts);

  return (
    <Grid container spacing={4} className={styles.wrapper}>
      {showLabels && (
        <Grid item className={styles.col}>
          <ListItemLabels labels={labels} offerType={offerType} />
          {highPriorityContact.priority < 4 && (
            <Box className={classNames(styles.label)} sx={{bgcolor: `background.default`, color: 'text.primary'}}>
              У нас є:{' '}
              <Box
                component={'span'}
                className={classNames(styles.label)}
                sx={{color: +highPriorityContact.priority <= 1 ? 'text.green' : 'background.info'}}
              >
                {propertyContactRole[highPriorityContact.role as ContactRole]}
              </Box>
            </Box>
          )}
        </Grid>
      )}
      <Grid item className={styles.col}>
        {source && source.id && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              {
                //@ts-ignore
                propertySourceNames[source.type]
              }{' '}
              ID:
            </Typography>
            <Typography variant={'caption'}>{source.id}</Typography>
          </Box>
        )}
        {!!lastTimeContactedAt && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              Дата контакту:
            </Typography>
            <Typography variant={'caption'}>
              <Tooltip disableInteractive title={formatCustomTime(lastTimeContactedAt, 'DD.MM.YYYY')} arrow>
                <Typography variant={'caption'} className={styles[timeColor]}>
                  {formatDate(lastTimeContactedAt)}
                </Typography>
              </Tooltip>
            </Typography>
          </Box>
        )}
        {!isMergeItem && (
          <Box className={styles.item}>
            <Typography variant={'caption'} sx={{color: 'text.primary'}}>
              {isBase ? 'Додано' : 'Парсінг'}:
            </Typography>
            <Tooltip disableInteractive title={formatCustomTime(createdAt)} arrow>
              <Link component={'div'} variant={'caption'} sx={{color: 'text.success', textDecoration: 'none'}}>
                {convertTimeAgo(createdAt)} {formatTimestamp(createdAt)}
              </Link>
            </Tooltip>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default PropertyItemFooter;
