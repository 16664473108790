import {createBrowserRouter, Navigate, Outlet} from 'react-router-dom';
import SignInPage from './pages/sign-In';
import {
  SIGN_IN_BASE_URL,
  ERROR_BASE_URL,
  SIGN_OUT_BASE_URL,
  PARSER_BASE_URL,
  PROPERTY_URL,
  SYSTEM_BASE_URL
} from '../constants/routes';
import HomeLayout from './Layouts/HomeLayout';
import PrivateRoute from './PrivateRoute';
import ErrorPage from './Error';
import SignOutPage from './pages/sign-out';
import ParsedPropertyListPage from './pages/properties-list';
import PropertyListPage from './pages/property-base';
import PropertyDetails from './pages/property-details';
import PropertyCreate from './pages/property-create';
import AdminsList from './pages/admins-list';
import PublicationsList from './pages/publications';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        path: '/',
        element: <Navigate to={`${PARSER_BASE_URL}/all`} replace />
      },
      {
        path: `${SIGN_IN_BASE_URL}`,
        element: (
          <HomeLayout>
            <SignInPage />
          </HomeLayout>
        )
      },
      {
        path: `${SIGN_OUT_BASE_URL}`,
        element: (
          <HomeLayout>
            <SignOutPage />
          </HomeLayout>
        )
      },
      {
        path: `${PARSER_BASE_URL}/:filter`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <ParsedPropertyListPage />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${PARSER_BASE_URL}/:filter/:id`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <PropertyDetails isParser={true} />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${PROPERTY_URL}`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <PropertyListPage />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${PROPERTY_URL}/:filter`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <PropertyListPage />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${PROPERTY_URL}/:filter/:id`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <PropertyDetails isParser={false} />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${PROPERTY_URL}/:filter/:id/publications`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <PublicationsList />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${PROPERTY_URL}/create`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <PropertyCreate />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${SYSTEM_BASE_URL}/admins`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <AdminsList />
            </HomeLayout>
          </PrivateRoute>
        )
      },
      {
        path: `${ERROR_BASE_URL}`,
        element: (
          <HomeLayout>
            <ErrorPage />
          </HomeLayout>
        )
      },
      {
        path: '*',
        element: <Navigate to={ERROR_BASE_URL} replace />
      }
    ]
  }
]);
