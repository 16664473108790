import React, {useCallback, useEffect, useState} from 'react';
import {Card, CardMedia, Button, Box} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './styles.module.scss';
import LiteboxSlider from '../liteboxSlider';
import FileUpload from '../file-loader';
import {mergeArrays} from '../../../helpers';
import {useFormWatcherContext} from '../../../contexts/FormWatcherProvider';
import NoImage from '../no-image';
import {ListManager} from 'react-beautiful-dnd-grid';
import ListCard from './list-card';

const DraggableImageGallery = ({images, setImages, showFileLoader = false, control, clearErrors}) => {
  const [{open, slideIndex}, setOpen] = useState({open: false, slideIndex: 0});
  const {setFormContextChanged} = useFormWatcherContext();

  const reorder = (list) => {
    const items = list.sortedList.slice().sort((first, second) => first.order - second.order);
    items.forEach((item, i) => {
      item.index = i;
    });
    setImages(items);
    setFormContextChanged(true);
    return items;
  };

  const [sortedList, setSortedList] = useState({sortedList: []});

  const sortList = () => {
    setSortedList((state) => ({
      ...state,
      sortedList: reorder(state)
    }));
  };

  useEffect(() => {
    images.forEach((image, i) => {
      image.index = i;
    });
    setSortedList({sortedList: images});
  }, [images]);

  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = sortedList.sortedList;

    if (destinationIndex === 0) {
      list[sourceIndex].order = list[0].order - 1;
      sortList();
      return;
    }

    if (destinationIndex === list.length - 1) {
      list[sourceIndex].order = list[list.length - 1].order + 1;
      sortList();
      return;
    }

    if (destinationIndex < sourceIndex) {
      list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
      sortList();
      return;
    }

    list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
    sortList();
  };

  const handleUploadSuccess = (data) => {
    setImages((prevImages) => mergeArrays(prevImages, data));
  };

  const onCrossClick = (id) => {
    const newImages = images.filter((img) => img.uuid !== id);
    setImages(newImages);
    setFormContextChanged(true);
  };

  return (
    <div className={styles.list}>
      {showFileLoader && (
        <FileUpload onUploadSuccess={handleUploadSuccess} control={control} clearErrors={clearErrors} />
      )}
      <div className={styles.listRow}>
        {images?.length > 0 ? (
          <ListManager
            items={sortedList.sortedList}
            direction="horizontal"
            maxItems={1}
            render={(item) => {
              return <ListCard item={item} onCrossClick={onCrossClick} setOpen={setOpen} />;
            }}
            onDragEnd={reorderList}
          />
        ) : (
          <Box className={styles.noImage}>
            <NoImage />
          </Box>
        )}
      </div>

      <LiteboxSlider images={images} isOpen={open} setIsOpen={setOpen} index={slideIndex} />
    </div>
  );
};

export default DraggableImageGallery;
